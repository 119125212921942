<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card-title-form">Conta
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">

              <div class="row top1">
                <div class="col-lg-6">
                  <label class="form-label" for="formCodTextoPlanoConta">Código Plano Conta</label>
                  <input type="text" id="formCodTextoPlanoConta" formControlName="formCodTextoPlanoConta"
                    [value]="form.get('formCodTextoPlanoConta')" name="formCodTextoPlanoConta"
                    class="form-control form-control-lg" maxlength="16" />
                  <app-field-error-message [control]="form.get('formCodTextoPlanoConta')"
                    label="Código do plano de contas"></app-field-error-message>
                </div>
                <div class="col-lg-6">
                  <label class="form-label" for="formTipoPlanoConta">Tipo</label>
                  <ngx-select-dropdown name="TipoPlanoContaDescr" id="TipoPlanoContaDescr"
                    formControlName="TipoPlanoContaDescr" (change)="changeTipoPlanoConta($event)"
                    [(ngModel)]="tipoPlanoContaDescr"
                    [config]="configTipo" [options]="TipoPlanoConta" class="form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </div>
              </div>

              <div class="row top1">
                <div class="col-lg-12">
                <label class="form-label" for="formNomePlanoConta">Descrição</label>
                <input type="text" id="formNomePlanoConta" formControlName="formNomePlanoConta"
                  [value]="form.get('formNomePlanoConta')" name="formNomePlanoConta"
                  class="form-control form-control-lg" maxlength="60" />
                <app-field-error-message [control]="form.get('formNomePlanoConta')"
                  label="descrição do plano de contas"></app-field-error-message>
                </div>
              </div>

              <div class="row top1">
                <div class="col-lg-12">
                <label class="form-label" for="codigoPlanoContaPai">Conta Pai</label>
                <app-PlanoConta-pesquisa
                  name="codigoPlanoContaPai" id="codigoPlanoContaPai" formControlName="codigoPlanoContaPai"
                  (PlanoContaOut)="changePlanoConta($event);" [CodigoPlanoContaPreench]="this.codigoPlanoContaPai">
                </app-PlanoConta-pesquisa>
                <app-field-error-message [control]="form.get('formContaPai')"
                  label="tipo do plano de contas"></app-field-error-message>
                </div>
              </div>

              <div class="row top1">
                <div class="col-lg-5">
                  <label class="form-label">Soma Relatório Exercício?</label>
                  <span class="d-block"><ui-switch name="formSomarRelatorio" id="formSomarRelatorio"
                    formControlName="formSomarRelatorio" (click)="toggleSomarRelatorio();"></ui-switch></span>
                </div>
                <div class="col-lg-3">
                  <label class="form-label">Ativo?</label>
                  <span class="d-block"> <ui-switch name="formAtivo" id="formAtivo" formControlName="formAtivo"
                      (click)="toggleAtivo();"></ui-switch></span>
                </div>

                <div class="col-lg-4">
                  <label class="form-label" for="formTipoPlanoConta">Coluna Relatório</label>
                  <ngx-select-dropdown name="ColunaRelatorioDescr" id="ColunaRelatorioDescr"
                    formControlName="ColunaRelatorioDescr" (change)="changeColunaRel($event)"
                    [(ngModel)]="colunaRelatorioDescr"
                    [config]="configTipo" [options]="TipoColunaRel" class="form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </div>
              </div>

              <div class="row top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary"
                    (click)="SalvaPlanoConta();">Salvar</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<div class="offcanvas offcanvas-top h-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center h-100">
      <div class="col-lg-6">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Conta
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">

          <div class="row top02">
            <div class="col-lg-6">
              <label class="form-label" for="formCodTextoPlanoConta">Código Plano Conta</label>
              <textarea type="text" disabled class="form-control form-control-lg" rows="3"
              placeholder="Necessário informar o código plano conta (máximo 16 caracteres)."></textarea>
            </div>
            <div class="col-lg-6">
              <label class="form-label" for="formTipoPlanoConta">Tipo</label>
              <textarea type="text" disabled class="form-control form-control-lg" rows="3"
              placeholder="Necessário informar o tipo de conta (Receita ou Despesa)."></textarea>
            </div>
          </div>

          <div class="row top02">
            <div class="col-lg-12">
              <label class="form-label" for="formNomePlanoConta">Descrição</label>
              <input disabled class="form-control form-control-lg" placeholder="Descrição da conta (até 60 caracteres)." />
            </div>
          </div>

          <div class="row top02">
            <div class="col-lg-12">
              <label class="form-label" for="codigoPlanoContaPai">Conta Pai</label>
              <input disabled class="form-control form-control-lg" placeholder="Conta a qual esta será subordinada no plano de contas." />
            </div>
          </div>

          <div class="row top02">
            <div class="col-lg-5">
              <label class="form-label">Soma Relatório Exercício?</label>
              <span class="d-block"> <ui-switch></ui-switch>
                <textarea type="text" disabled class="form-control form-control-lg" rows="2"
                placeholder="Se esta conta entra ou não no relatório de exercício"></textarea>
              </span>
            </div>
            <div class="col-lg-3">
              <label class="form-label">Ativo?</label>
              <span class="d-block"> <ui-switch></ui-switch>
                <input disabled class="form-control form-control-lg top02"
                placeholder="Está ativo?" />
              </span>
            </div>
            <div class="col-lg-4">
              <label class="form-label" for="formTipoPlanoConta">Coluna Relatório</label>
              <textarea type="text" disabled class="form-control form-control-lg" rows="3"
              placeholder="Se esta conta entra ou não no relatório de fluxo financeiro"></textarea>
            </div>
          </div>

          <div class="w-100 top02"><button type="button" class="btn btn-warning">Cancelar</button><label
              style="margin-left: 4px; margin-right: 1%;">Retorna para tela de lista de contas do plano.</label></div>
          <div class="w-100 top02"><button type="button" class="btn btn-primary">Salvar</button><label
              style="margin-left: 4px; margin-right: 1%;">Salva a conta selecionada.</label></div>
        </div>
      </div>
    </div>
  </div>
</div>
