import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Orcamento } from '../models/orcamento';
import { ExcelService } from 'src/app/excel.service';
import OrcamentoService from '../orcamento-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-orcamento-lista',
  templateUrl: './orcamento-lista.component.html',
  styleUrls: ['./orcamento-lista.component.css'],
})
export class OrcamentoListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private orcamentoService: OrcamentoService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
    public modalService: BsModalService
  ) {}

  orcamento: Orcamento[] = [];
  orcamentoOriginal: Orcamento[] = [];
  novoOrcamento: Orcamento = new Orcamento();
  DadosExcell: any = [];
  ColunasExcell: any = [];
  codigoCompra: number = 0;
  descricaoOrcamento: string = '';
  modalRef?: BsModalRef;
  mostrarEncerrados: boolean = false;

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaOrcamentos();
  }

  BuscaOrcamentos(): void {
    this.spinner.show();
    try {
      this.orcamentoService.GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.orcamentoOriginal = response.OrcamentoLista;
            }
            this.orcamento = this.orcamentoOriginal;
            this.mostrarEncerrados = false;
            this.filtrarPlanoConta();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  Edicao(orcamento: Orcamento) {
    this.router.navigateByUrl('/administrativo/orcamento/edicao', {
      state: { orcamento: orcamento },
    });
  }

  Adicionar() {
    this.spinner.show();
    this.novoOrcamento = new Orcamento();
    this.novoOrcamento.CodigoCompra = -1;
    this.novoOrcamento.DataCompra = new Date();
    this.novoOrcamento.TipoCompra = 'O';
    this.novoOrcamento.SituacaoCompra = 'A';
    this.novoOrcamento.SituacaoCompraDescr = 'Aberta';
    this.spinner.hide();
    this.router.navigateByUrl('/administrativo/orcamento/edicao', {
      state: { orcamento: this.novoOrcamento },
    });
  }

  toggleMostrarEncerrados() {
    this.mostrarEncerrados = !this.mostrarEncerrados;
    this.filtrarPlanoConta();
  }

  filtrarPlanoConta() {
    if (this.mostrarEncerrados) {
      this.orcamento = this.orcamentoOriginal;
    } else {
      this.orcamento = this.orcamentoOriginal.filter(
        (item) => item.SituacaoCompra == 'A'
      );
    }
  }


  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.orcamentoOriginal.filter(function (d: any) {
      return (
        d.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1 ||
        d.DescExtraPlanoconta.toLowerCase().indexOf(val) !== -1 ||
        d.NomeFornOrcam1.toLowerCase().indexOf(val) !== -1 ||
        d.NomeFornOrcam2.toLowerCase().indexOf(val) !== -1 ||
        d.NomeFornOrcam3.toLowerCase().indexOf(val) !== -1 ||
        Utils.getDateddMMyyy(d.DataCompra).indexOf(val) !== -1 ||
        !val
      );
    });
    // update the rows
    this.orcamento = temp;
    return true;
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioOrcamento';
    var Titulo: string = 'Relatório Orçamentos';
    this.ColunasExcell = [];
    this.ColunasExcell.push('Plano Conta');
    this.ColunasExcell.push('Complemento');
    this.ColunasExcell.push('Data');
    this.ColunasExcell.push('Fornecedor 1');
    this.ColunasExcell.push('Valor 1');
    this.ColunasExcell.push('Fornecedor 2');
    this.ColunasExcell.push('Valor 2');
    this.ColunasExcell.push('Fornecedor 3');
    this.ColunasExcell.push('Valor 3');

    this.DadosExcell = [];
    this.orcamento.forEach((item: Orcamento) => {
      const varExcell: any = [];
      varExcell.push(item.DescricaoPlanoConta);
      varExcell.push(item.DescExtraPlanoconta);
      varExcell.push(Utils.getDateddMMyyy(item.DataCompra));
      varExcell.push(item.NomeFornOrcam1);
      varExcell.push(Utils.converterValorMoeda(item.OrcamentoValor1));
      varExcell.push(item.NomeFornOrcam2);
      varExcell.push(Utils.converterValorMoeda(item.OrcamentoValor2));
      varExcell.push(item.NomeFornOrcam3);
      varExcell.push(Utils.converterValorMoeda(item.OrcamentoValor3));

      this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
      nomeArquivo,
      Titulo,
      this.ColunasExcell,
      this.DadosExcell
    );
  }

  // Função de imprimir
  Imprimir(row: Orcamento): void {
    // Formate o conteúdo a ser impresso
    const printContent = `
    <div style="font-family: Arial, sans-serif; width: 100%; text-align: left;">
        <h3 style="text-align: center;">Orçamento</h3>
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
            <tr>
                <td><strong>Plano de Contas:</strong></td>
                <td>${row.DescricaoPlanoConta}</td>
            </tr>
            <tr>
                <td><strong>Complemento:</strong></td>
                <td>${row.DescExtraPlanoconta}</td>
            </tr>
            <tr>
                <td><strong>Data Compra:</strong></td>
                <td>${Utils.getDateddMMyyy(row.DataCompra)}</td>
            </tr>
            <tr>
                <td><string>Valor Compra:</string></td>
                <td>${row.ValorCompra}</td>
            </tr>
            <tr>
                <td><string>Código:</string></td>
                <td>${row.CodigoCompra}</td>
            </tr>
        </table>

        <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
            <thead>
                <tr style="background-color: #f0f0f0;">
                    <th style="border: 1px solid black; padding: 5px;">Fornecedor</th>
                    <th style="border: 1px solid black; padding: 5px;">Pessoa</th>
                    <th style="border: 1px solid black; padding: 5px;">Fone</th>
                    <th style="border: 1px solid black; padding: 5px;">Valor</th>
                    <th style="border: 1px solid black; padding: 5px;">Forma Pagamento</th>
                    <th style="border: 1px solid black; padding: 5px;">Observações</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.NomeFornOrcam1
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoPes1
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoFone1
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoValor1
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoCond1
                    }
                    </td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoObs1
                    }</td>
                </tr>
                <tr>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.NomeFornOrcam2
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoPes2
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoFone2
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoValor2
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoCond2
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoObs2
                    }</td>
                </tr>
                <tr>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.NomeFornOrcam3
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoPes3
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoFone3
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoValor3
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoCond3
                    }</td>
                    <td style="border: 1px solid black; padding: 5px;">${
                      row.OrcamentoObs3
                    }</td>
                </tr>
            </tbody>
        </table>
    </div>
    `;

    // Criar um iframe invisível para imprimir
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';

    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.open();
      iframeDoc.write(`
        <html>
            <head>
                <title>Imprimir Orçamento</title>
            </head>
            <body>${printContent}</body>
        </html>
        `);
      iframeDoc.close();

      // Esperar o conteúdo carregar no iframe e chamar a impressão
      iframe.contentWindow?.focus();
      iframe.contentWindow?.print();
    }

    // Remover o iframe após a impressão
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  }

  openModalExcluir(
    templateModal: TemplateRef<any>,
    CodCompra: number,
    DescrPlanoConta: string,
    DescrExtraPlanoConta: string
  ): void {
    this.codigoCompra = CodCompra;
    this.descricaoOrcamento = DescrPlanoConta + ' ' + DescrExtraPlanoConta;
    this.modalRef = this.modalService.show(templateModal, {
      class: 'modal-dialog-centered',
    });
  }

  ExcluiOrcamentoByCodigo() {
    this.modalRef?.hide();
    this.spinner.show();
    try {
      this.orcamentoService.ExcluiOrcamentoByCodigo(this.codigoCompra)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            this.spinner.hide();
            this.BuscaOrcamentos();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  closeModal(): void {
    this.modalRef?.hide();
  }

}
