import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetOrcamentoLista } from './models/ret-orcamento';

import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';
import { Orcamento } from './models/orcamento';

@Injectable({
  providedIn: 'root',
})
export default class OrcamentoService {
  private readonly baseURL: string = '';
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private authStorageService: AuthStorageService
  ) {
    this.baseURL = `${environment.OrcamentoWS}`;
  }

  GetListaGeral(): Observable<RetOrcamentoLista> {
    const url = `${this.baseURL}/ListaGeral`;
    return this.httpClient.get<RetOrcamentoLista>(url).pipe(
      take(1),
      map((response: RetOrcamentoLista) => {
        return response;
      })
    );
  }

  SalvaOrcamento(orcamento: Orcamento): Observable<RetError> {
    let bodyString = JSON.stringify(orcamento);

    const url = `${this.baseURL}/SalvaOrcamento`;
    return this.httpClient.post<RetError>(url, bodyString).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );
  }

  ExcluiOrcamentoByCodigo(CodigoCompra: number): Observable<RetError> {
    const params = new HttpParams().set('CodigoCompra', CodigoCompra);

    const url = `${this.baseURL}/ExcluiOrcamentoByCodigo`;
    return this.httpClient.get<RetError>(url, { params: params }).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );
  }

}
