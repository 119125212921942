import { Utils } from "src/app/Projeto/Utils/utils";
import { SocioResumo } from "../../associados/models/associados";
import { PlanoConta } from "../../planoconta/models/planoconta";

export class Orcamento {
    CodigoCompra: number;
    CodigoFornecedor: number;
    NomeFornecedor: string;
    CodigoPlanoConta: number;
    DescricaoPlanoConta: string;
    DescExtraPlanoconta: string;
    TipoCompra: string;
    SituacaoCompra: string;
    SituacaoCompraDescr: string;
    DescricaoCompra: string;
    NumeroNota: string;
    DataCompra: Date;
    ValorCompra: number;

    CodigoFornOrcam1: number;
    NomeFornOrcam1: string;
    OrcamentoPes1: string;
    OrcamentoFone1: string;
    OrcamentoValor1: number;
    OrcamentoCond1: string;
    OrcamentoObs1: string;

    CodigoFornOrcam2: number;
    NomeFornOrcam2: string;
    OrcamentoPes2: string;
    OrcamentoFone2: string;
    OrcamentoValor2: number;
    OrcamentoCond2: string;
    OrcamentoObs2: string;

    CodigoFornOrcam3: number;
    NomeFornOrcam3: string;
    OrcamentoPes3: string;
    OrcamentoFone3: string;
    OrcamentoValor3: number;
    OrcamentoCond3: string;
    OrcamentoObs3: string;

    constructor() {
        this.CodigoCompra = 0;
        this.CodigoFornecedor= 0;
        this.NomeFornecedor = '';
        this.CodigoPlanoConta = 0;
        this.DescricaoPlanoConta = '';
        this.DescExtraPlanoconta = '';
        this.TipoCompra = '';
        this.SituacaoCompra = '';
        this.SituacaoCompraDescr = '';
        this.DescricaoCompra = '';
        this.NumeroNota = '';
        this.ValorCompra = 0;

        this.CodigoFornOrcam1 = 0;
        this.NomeFornOrcam1 = '';
        this.OrcamentoPes1 = '';
        this.OrcamentoFone1 = '';
        this.OrcamentoValor1 = 0;
        this.OrcamentoCond1 = '';
        this.OrcamentoObs1 = '';

        this.CodigoFornOrcam2 = 0;
        this.NomeFornOrcam2 = '';
        this.OrcamentoPes2 = '';
        this.OrcamentoFone2 = '';
        this.OrcamentoValor2 = 0;
        this.OrcamentoCond2 = '';
        this.OrcamentoObs2 = '';

        this.CodigoFornOrcam3 = 0;
        this.NomeFornOrcam3 = '';
        this.OrcamentoPes3 = '';
        this.OrcamentoFone3 = '';
        this.OrcamentoValor3 = 0;
        this.OrcamentoCond3 = '';
        this.OrcamentoObs3 = '';
    }



}
