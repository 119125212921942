import { Orcamento } from './../models/orcamento';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import OrcamentoService from '../orcamento-service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { PlanoConta } from '../../planoconta/models/planoconta';
import { ListasGeraisService } from '../../listasgerais/listasgerais.service';
import { Lista } from '../../models-gerais/lista';
import { SocioResumo } from '../../associados/models/associados';

@Component({
  selector: 'app-orcamento-edicao',
  templateUrl: './orcamento-edicao.component.html',
  styleUrls: ['./orcamento-edicao.component.css'],
})
export class OrcamentoEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  form: FormGroup;
  orcamento: Orcamento = new Orcamento();
  codigoPlanoConta: number = 0;

  Situacao: Lista = new Lista();
  configSituacao = {
    search: true,
    placeholder: 'Selecione a situação', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'Descricao', //TROCA CAMPO DESCRIÇÃO
    height: '220px',
    limitTo: 0,
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private orcamentoService: OrcamentoService,
    private utilsservice: UtilsService,
    private listasGeraisService: ListasGeraisService
  ) {}

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };

    const { orcamento } = window.history.state;
    this.orcamento = orcamento;
    this.codigoPlanoConta = this.orcamento.CodigoPlanoConta;
    this.orcamento.DataCompra = Utils.getDateUTC(this.orcamento.DataCompra);
    this.criarForm();
    this.GetListasFixas();
  }

  GetListasFixas(): void {
    this.spinner.show();

    let ArrayListas: string[] = [];
    ArrayListas.push('StatusCompraOrca');

    try {
      this.listasGeraisService
        .GetListasFixas(ArrayListas)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.Situacao = response.ListasFixas[0];
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  changePlanoConta($event: PlanoConta) {
    this.codigoPlanoConta = $event.CodigoPlanoConta;
  }

  changeSituacao($event: any) {
    this.orcamento.SituacaoCompra = $event.value.Valor;
    this.orcamento.SituacaoCompraDescr = $event.value.Descricao;
  }

  AlteraData($event: any) {
    this.orcamento.DataCompra = new Date($event);
  }

  changeFornec1($event: SocioResumo) {
    this.orcamento.CodigoFornOrcam1 = $event.CodigoSocio;
  }

  changeFornec2($event: SocioResumo) {
    this.orcamento.CodigoFornOrcam2 = $event.CodigoSocio;
  }

  changeFornec3($event: SocioResumo) {
    this.orcamento.CodigoFornOrcam3 = $event.CodigoSocio;
  }

  SalvaOrcamento(): void {
    let requestOrcamento: Orcamento = new Orcamento();
    requestOrcamento.CodigoCompra = this.orcamento.CodigoCompra;
    requestOrcamento.CodigoPlanoConta = this.codigoPlanoConta;
    requestOrcamento.DescExtraPlanoconta =
      this.form.get('formComplemento')?.value;
    requestOrcamento.SituacaoCompra = this.orcamento.SituacaoCompra;
    requestOrcamento.TipoCompra = this.orcamento.TipoCompra;
    requestOrcamento.DataCompra =  Utils.getDateUTC(this.orcamento.DataCompra);

    // Adicionando campos para o fornecedor 1
    requestOrcamento.CodigoFornOrcam1 = this.orcamento.CodigoFornOrcam1;
    requestOrcamento.OrcamentoPes1 = this.form.get('formPessoa1')?.value;
    requestOrcamento.OrcamentoValor1 = this.form.get('formValor1')?.value;
    requestOrcamento.OrcamentoCond1 = this.form.get('formCondicao1')?.value;
    requestOrcamento.OrcamentoFone1 = this.form.get('formNumero1')?.value;
    requestOrcamento.OrcamentoObs1 = this.form.get('formObservacao1')?.value;

    // Adicionando campos para o fornecedor 2
    requestOrcamento.CodigoFornOrcam2 = this.orcamento.CodigoFornOrcam2;
    requestOrcamento.OrcamentoPes2 = this.form.get('formPessoa2')?.value;
    requestOrcamento.OrcamentoValor2 = this.form.get('formValor2')?.value;
    requestOrcamento.OrcamentoCond2 = this.form.get('formCondicao2')?.value;
    requestOrcamento.OrcamentoFone2 = this.form.get('formNumero2')?.value;
    requestOrcamento.OrcamentoObs2 = this.form.get('formObservacao2')?.value;

    // Adicionando campos para o fornecedor 3
    requestOrcamento.CodigoFornOrcam3 = this.orcamento.CodigoFornOrcam3;
    requestOrcamento.OrcamentoPes3 = this.form.get('formPessoa3')?.value;
    requestOrcamento.OrcamentoValor3 = this.form.get('formValor3')?.value;
    requestOrcamento.OrcamentoCond3 = this.form.get('formCondicao3')?.value;
    requestOrcamento.OrcamentoFone3 = this.form.get('formNumero3')?.value;
    requestOrcamento.OrcamentoObs3 = this.form.get('formObservacao3')?.value;
    if (this.form.valid) {
      if (requestOrcamento.CodigoPlanoConta == 0)
        this.messageService.showAlertDanger(
          'Plano de Contas deve ser informada.'
        );
      else {
        this.spinner.show();
        try {
          this.orcamentoService
            .SalvaOrcamento(requestOrcamento)
            .pipe(take(1))
            .subscribe({
              next: (response) => {
                this.spinner.hide();
                if (response.Error) {
                  this.messageService.showAlertDanger(response.ErrorMessage);
                } else {
                  this.voltar();
                }
              },
              error: (error) => {
                this.spinner.hide();
                this.utilsservice.showHttpError(error);
              },
            });
        } catch (error) {
          this.spinner.hide();
          this.utilsservice.showHttpError(error);
        }
      }
    } else {
      FormUtils.validateFields(this.form);
    }
  }

  criarForm() {
    this.form = this.formBuilder.group({
      formComplemento: [
        this.orcamento.DescExtraPlanoconta,
        [Validators.maxLength(50)],
      ],
      formSituacao: [this.orcamento.SituacaoCompraDescr],
      formDataCompra: [this.orcamento.DataCompra, Validators.required],

      formPessoa1: [this.orcamento.OrcamentoPes1],
      formValor1: [this.orcamento.OrcamentoValor1],
      formCondicao1: [this.orcamento.OrcamentoCond1],
      formNumero1: [this.orcamento.OrcamentoFone1],
      formObservacao1: [this.orcamento.OrcamentoObs1],

      formPessoa2: [this.orcamento.OrcamentoPes2],
      formValor2: [this.orcamento.OrcamentoValor2],
      formCondicao2: [this.orcamento.OrcamentoCond2],
      formNumero2: [this.orcamento.OrcamentoFone2],
      formObservacao2: [this.orcamento.OrcamentoObs2],

      formPessoa3: [this.orcamento.OrcamentoPes3],
      formValor3: [this.orcamento.OrcamentoValor3],
      formCondicao3: [this.orcamento.OrcamentoCond3],
      formNumero3: [this.orcamento.OrcamentoFone3],
      formObservacao3: [this.orcamento.OrcamentoObs3],
    });
  }

  voltar() {
    this.router.navigateByUrl('/administrativo/orcamentos');
  }
}
