<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div>

      <div class="card-title-form col-lg-12">Relatórios</div>

      <div class="table-responsive table-responsive-overflow-450 bottom1">
        <form [formGroup]="form">

          <div class="row top1">
            <div class="card bg-relato">
              <div class="card-header form-label">Datas de Todos Relatórios</div>
              <div class="row">
                <span class="col-lg-6">
                  <label class="form-label" style="width: 100%;">Data Inicial:</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }"
                    name="DataInicial" id="DataInicial" formControlName="DataInicial"
                    (bsValueChange)="changeDtIni($event)" [bsValue]="dataInicial">
                </span>
                <span class="col-lg-6">
                  <label class="form-label" style="width: 100%;">Data Final:</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }"
                    name="DataFinal" id="DataFinal" formControlName="DataFinal" (bsValueChange)="changeDtFim($event)"
                    [bsValue]="dataFinal">
                </span>
              </div>
            </div>
            <div class="card bg-relato">
              <div class="card-header">Associados</div>

              <div class="row">
                <label class="form-label">Categoria:</label>
                <app-categoria-pesquisa (CategoriaOut)="changeCategoria($event);"
                  [CodigoCategoriaPreench]="this.CategoriaObj.CodigoCategoria">
                </app-categoria-pesquisa>
              </div>
              <div class="row top02" style="align-content: end;">
                <span class="col-lg-4">
                  <button type="button" class="btn btn-warning full"
                    (click)="AssocListagem('ListaSocios');">Associados</button>
                </span>
                <span class="col-lg-4">
                  <button type="button" class="btn btn-warning full" (click)="AssocListagem('Fila');">Fila</button>
                </span>
                <span class="col-lg-4">
                  <button type="button" class="btn btn-warning full"
                    (click)="AssocListagem('Aniver');">Aniversário</button>
                </span>
              </div>
              <div class="row top02">
                <label class="form-label">Associado:</label>
                <app-associado-pesquisa name="CodigoSocioHistCat" id="CodigoSocioHistCat"
                  formControlName="CodigoSocioHistCat" [CodigoSocioPreench]="this.AssociadoObjHistCat.CodigoSocio"
                  (AssociadoOut)="changeAssociadoHistCat($event);">
                </app-associado-pesquisa>
              </div>
              <div class="row top02" style="align-content: end;">
                <span class="col-lg-6">
                  <button type="button" class="btn btn-warning full"
                    (click)="AssocListagem('HistoricoCategoria');">Histórico Categorias</button>
                </span>
              </div>
              <br />
            </div>

            <div class="card bg-relato">
              <div class="card-header">Consultas</div>

              <div class="row">
                <label class="form-label">Terapeuta:</label>
                <app-terapeuta-pesquisa [CodigoSocioPreench]="this.AssociadoObj.CodigoSocio"
                  (AssociadoOut)="changeTerapeuta($event);">
                </app-terapeuta-pesquisa>
              </div>
              <div class="row top02">
                <span class="col-lg-6">
                  <button type="button" class="btn btn-warning full" (click)="ConsultasListagem('Consulta');">Resumo
                    Consultas</button>
                </span>
                <span class="col-lg-6">
                  <button type="button" class="btn btn-warning full" (click)="ConsultasListagem('Mapa');">Mapa
                    Consultas</button>
                </span>
              </div>
              <div class="row top02">
                <span class="col-lg-6">
                  <button type="button" class="btn btn-warning full" (click)="ConsultasListagem('Sala');">Utilização
                    Salas</button>
                </span>
                <span class="col-lg-6">
                  <button type="button" class="btn btn-warning full" (click)="ConsultasListagem('ConsultaPE');">Primeira
                    Entrevista</button>
                </span>
              </div>

            </div>
          </div>

          <div class="row top1">
            <div class="card bg-relato">
              <div class="card-header">Exercícios</div>

              <div class="row">
                <label class="form-label">Conta:</label>
                <app-PlanoConta-pesquisa [CodigoPlanoContaPreench]="this.PlanoContaObj.CodigoPlanoConta"
                  (PlanoContaOut)="changePlanoConta($event);">
                </app-PlanoConta-pesquisa>
              </div>
              <div class="row top02">
                <span class="col-lg-6">
                  <label class="form-label">Período:</label>
                  <ngx-select-dropdown name="TipoRelFluxo" id="TipoRelFluxo" formControlName="TipoRelFluxo"
                    (change)="changeTipoRelFluxo($event)" [config]="configTipo" [options]="TipoRelFluxo"
                    class="form-control form-control-lg form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </span>
                <span class="col-lg-6">
                  <label class="form-label">Incluir Inativas?</label>
                  <span class="d-block"> <ui-switch (change)="changeInativa($event);"></ui-switch></span>
                </span>
              </div>
              <div class="row top02">
                <span class="col-lg-6">
                  <button type="button" class="btn btn-warning full"
                    (click)="ConsultasExercicio('PLAN');">Exercício</button>
                </span>
                <span class="col-lg-6">
                  <button type="button" class="btn btn-warning full" (click)="ConsultasExercicio('FLUXONV');">Fluxo
                    Financeiro</button>
                </span>
              </div>
              <br />
            </div>
            <div class="card bg-relato">
              <div class="card-header">Lançamentos</div>

              <div class="row">
                <label class="form-label">Conta:</label>
                <app-PlanoConta-pesquisa [CodigoPlanoContaPreench]="this.PlanoContaObj.CodigoPlanoConta"
                  (PlanoContaOut)="changePlanoConta($event);">
                </app-PlanoConta-pesquisa>
              </div>
              <div class="row top02">
                <span class="col-lg-6" style="align-content: end;">
                  <label class="form-label">Tipo:</label>
                  <ngx-select-dropdown name="TipoRelLancamento" id="TipoRelLancamento"
                    formControlName="TipoRelLancamento" (change)="changeTipoRelLancamento($event)" [config]="configTipo"
                    [options]="TipoRelLancamento" class="form-control form-control-lg form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </span>
                <span class="col-lg-3" style="align-content: end;">
                  <button type="button" class="btn btn-warning full" (click)="ConsultasLancamento('Lancamento');">Com
                    Saldo</button>
                </span>
                <span class="col-lg-3" style="align-content: end;">
                  <button type="button" class="btn btn-warning full" (click)="ConsultasLancamento('LancaSimples');">Sem
                    Saldo</button>
                </span>
              </div>

            </div>
            <div class="card bg-relato">
              <div class="card-header">Contas a Pagar</div>

              <div class="row">
                <label class="form-label">Fornecedor:</label>
                <app-fornecedor-pesquisa [CodigoSocioPreench]="this.AssociadoObj.CodigoSocio"
                  (AssociadoOut)="changeAssociado($event);">
                </app-fornecedor-pesquisa>
              </div>
              <div class="row top02">
                <span class="col-lg-6" style="align-content: end;">
                  <label class="form-label">Tipo:</label>
                  <ngx-select-dropdown name="TipoRelPagamento" id="TipoRelPagamento" formControlName="TipoRelPagamento"
                    (change)="changeTipoRelPagamento($event)" [config]="configTipo" [options]="TipoRelPagamento"
                    class="form-control form-control-lg form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </span>
                <span class="col-lg-6" style="align-content: end;">
                  <button type="button" class="btn btn-warning full" (click)="ConsultasContasPagar('CtPagar');">Contas
                    a Pagar</button>
                </span>
              </div>

            </div>
          </div>

          <div class="row top1">
            <div class="card bg-relato">
              <div class="card-header">Contas a Receber</div>

              <div class="row">
                <label class="form-label">Associado:</label>
                <app-associado-pesquisa name="CodigoSocioEnc" id="CodigoSocioEnc" formControlName="CodigoSocioEnc"
                  [CodigoSocioPreench]="this.AssociadoObj.CodigoSocio" (AssociadoOut)="changeAssociado($event);">
                </app-associado-pesquisa>
              </div>
              <div class="row top02">
                <span class="col-lg-6" style="align-content: end;">
                  <label class="form-label">Tipo:</label>
                  <ngx-select-dropdown name="TipoRelRecebimento" id="TipoRelRecebimento"
                    formControlName="TipoRelRecebimento" (change)="changeTipoRelRecebimento($event)"
                    [config]="configTipo" [options]="TipoRelRecebimento"
                    class="form-control form-control-lg form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </span>
                <span class="col-lg-3" style="align-content: end;">
                  <button type="button" class="btn btn-warning full"
                    (click)="ConsultasContasReceber('CtReceber');">Receber</button>
                </span>
                <span class="col-lg-3" style="align-content: end;">
                  <button type="button" class="btn btn-warning full"
                    (click)="ConsultasContasReceber('Boleto');">Boletos</button>
                </span>
              </div>

            </div>
            <div class="card bg-relato">
              <div class="card-header">Atividades</div>

              <div class="row">
                <span class="col-lg-12">
                  <label class="form-label">Tipo:</label>
                  <ngx-select-dropdown name="TipoRelAtividades" id="TipoRelAtividades"
                    formControlName="TipoRelAtividades" (change)="changeTipoRelAtividade($event)" [config]="configTipo"
                    [options]="TipoRelAtividades" class="form-control form-control-lg form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </span>
                <span class="col-lg-6 top02" style="align-content: end;">
                  <button type="button" class="btn btn-warning full"
                    (click)="ConsultasAtividades('Atividade');">Atividades</button>
                </span>
              </div>

            </div>
            <div class="card bg-relato">
              <div class="card-header">Seminários, Supervisões e Trabalhos em Aberto</div>

                <div class="row">
                  <span class="col-lg-12">
                    <label class="form-label">Tipo:</label>
                    <ngx-select-dropdown name="TipoRelSupervisao" id="TipoRelSupervisao"
                      formControlName="TipoRelSupervisao" (change)="changeTipoRelSupervisao($event)"
                      [config]="configTipo" [options]="TipoRelSupervisao"
                      class="form-control form-control-lg form_control_ngx_select_dropdown">
                    </ngx-select-dropdown>
                  </span>
                </div>
                <div class="row top02">
                  <span class="col-lg-6">
                    <button type="button" class="btn btn-warning full"
                      (click)="ConsultasSupervisoes('Trabalho');">Trabalhos Anuais</button>
                  </span>
                  <span class="col-lg-6">
                    <button type="button" class="btn btn-warning full" (click)="ConsultasSupervisoes('TC');">Trabalhos
                      Conclusão</button>
                  </span>
                </div>
                <div class="row top02">
                  <span class="col-lg-6">
                    <button type="button" class="btn btn-warning full"
                      (click)="ConsultasSupervisoes('HistoricoGeral');">Histórico Geral</button>
                  </span>
                  <span class="col-lg-6">
                    <button type="button" class="btn btn-warning full"
                      (click)="ConsultasSupervisoes('Supervisao');">Supervisões</button>
                  </span>
                </div>
                <div class="row top02">
                  <span class="col-lg-6">
                    <button type="button" class="btn btn-warning full"
                      (click)="ConsultasSupervisoes('Seminarios');">Seminários Ativos</button>
                  </span>
                  <span class="col-lg-6">
                    <button type="button" class="btn btn-warning full"
                      (click)="ConsultasSupervisoes('SugestaoSeminario');">Possibilidades Seminários</button>
                  </span>
                </div>
                <div class="let-rowtop02">
                  <label class="form-label">Co-Coordenador:</label>
                  <app-associadotodos-pesquisa name="CodigoSocioCoCoor" id="CodigoSocioCoCoor"
                    formControlName="CodigoSocioCoCoor" [CodigoSocioPreench]="this.AssociadoObjCoCoor.CodigoSocio"
                    (AssociadoOut)="changeAssociadoCoCoor($event);">
                  </app-associadotodos-pesquisa>
                </div>
                <div class="row top02">
                  <span class="col-lg-6">
                    <button type="button" class="btn btn-warning full"
                      (click)="ConsultasSupervisoes('CoCoordenacao');">Co-Coordenações</button>
                  </span>
                </div>
                <div class="row top02">
                  <span class="col-lg-12">
                    <label class="form-label">Seminários Abertos:</label>
                    <ngx-select-dropdown name="TipoRelSeminario" id="TipoRelSeminario"
                      formControlName="TipoRelSeminario" (change)="changeTipoRelSeminario($event)"
                      [config]="configSeminario" [options]="ListaSeminariosComPart"
                      class="form-control form-control-lg form_control_ngx_select_dropdown">
                    </ngx-select-dropdown>
                  </span>
                </div>
                <div class="row top02">
                  <span class="col-lg-6">
                    <button type="button" class="btn btn-warning full"
                      (click)="ConsultasSupervisoes('Chamada');">Chamada</button>
                  </span>
                </div>
                <br />
            </div>
          </div>
          <br />
        </form>
      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>
