export class Previsao {
    CodigoPrevisao: number;
    CodigoPlanoConta: number;
    CodTextoPlanoConta: string;
    DescricaoPlanoConta: string;
    TipoPlanoConta: string;
    DataPrevisao: Date;
    ValorPrevisao: number;
    Repeticao: number;
    constructor() {
        this.CodigoPrevisao = 0;
        this.CodigoPlanoConta = 0;
        this.DescricaoPlanoConta = '';
        this.CodTextoPlanoConta = '';
        this.TipoPlanoConta = '';
        this.ValorPrevisao = 0;
        this.Repeticao = 1;
    }

}
