<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="table-responsive h-100">

          <div class="card-title-form">Lançamentos
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar"
              placement="top" [adaptivePosition]="false">
              <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
                <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
            </span>
          </div>

          <a style="margin-bottom: 0.5%;text-align: left;padding:0.6rem 0.8rem;font-weight: bolder;"
          class="w-100 btn btn-light" href="#filtros" data-bs-toggle="collapse"><i class="fa-solid fa-filter iconeTamanho"></i>Filtrar Pesquisa</a>

          <div class="collapse" id="filtros">
            <div>
              <div class="w-100 row">
                <!-- Plano de Contas -->
                <div class="col-lg-4">
                  <label class="form-label" for="formPlanoContas">Plano de Contas</label>
                  <app-PlanoConta-pesquisa name="codigoPlanoConta" id="codigoPlanoConta"
                    formControlName="codigoPlanoConta" (PlanoContaOut)="changePlanoConta($event);"
                    [CodigoPlanoContaPreench]="this.codigoPlanoConta">
                  </app-PlanoConta-pesquisa>
                </div>

                <div class="col-lg-3">
                  <label class="form-label" for="formFornecedor">Fornecedor</label>
                  <app-fornecedor-pesquisa id="formFornecedor" formControlName="formFornecedor" name="formFornecedor"
                    [CodigoSocioPreench]="this.codigoFornecedor" (AssociadoOut)="changeFornec($event);">
                  </app-fornecedor-pesquisa>
                </div>

                <div class="col-lg-3">
                  <label class="form-label" for="formTipoLan">Tipo Lançamento</label>
                  <ngx-select-dropdown name="formTipoLan" id="formTipoLan" formControlName="formTipoLan"
                    (change)="changeTipoLanc($event.value)" [config]="configTipoLan" [options]="listaTipoLancamentoOriginal"
                    class="form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </div>

                <div class="col-lg-2">
                  <button type="button" class="btn btn-sm btn-warning float-end" (click)="BuscaLancamentos();"><i
                      class="fa-solid fa-search icone-para-botao"></i>Pesquisar</button>
                </div>
              </div>
            </div>
          </div>

          <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
            <span class="col-lg-8">
              <input type="text" #search id="search" class="form-control form-control-lg "
                placeholder="Pesquisar Plano de Contas, Complemento, Fornecedor, Data Lançamento ou Tipo na listagem abaixo..." (keyup)="updateFilter($event)" />
            </span>

            <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-4">
              <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass"
                tooltip="Exporta para o Excel os dados consultados abaixo." placement="top" [adaptivePosition]="false"
                (click)="generateExcell();">
                <i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar Excel</button>
            </span>
          </div>

          <div class="card-body-form">
            <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="lancamento"
              [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="!isMobile ? 65 : 250"
              [scrollbarH]="false" [scrollbarV]="true" [limit]="7" [messages]="my_messages" style="height: 360px;"
              [sorts]="[{prop: 'DataLancamento', dir: 'desc'}]">

              <ngx-datatable-column prop="DescricaoPlanoConta" [draggable]="false" [flexGrow]="5">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Plano de Contas</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.DescricaoPlanoConta}}" placement="auto"
                    [adaptivePosition]="false">{{row.DescricaoPlanoConta}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="DescExtraPlanoconta" [draggable]="false" [flexGrow]="3">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Complemento</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.DescExtraPlanoconta}}" placement="auto"
                    [adaptivePosition]="false">{{row.DescExtraPlanoconta}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="NomeFornecedor" [draggable]="false" [flexGrow]="3">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Fornecedor</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.NomeFornecedor}}" placement="auto"
                    [adaptivePosition]="false">{{row.NomeFornecedor}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="DataLancamento" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Data</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.DataLancamento | date: 'dd/MM/yyyy'}}" placement="auto"
                    [adaptivePosition]="false">{{ row.DataLancamento | date: 'dd/MM/yyyy' }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="ValorLancamento" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
                [headerClass]="'ngx-direita'">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Valor</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.ValorLancamento  | currency: 'BRL'}}" placement="auto"
                    [adaptivePosition]="false">{{row.ValorLancamento | currency: 'BRL'}}</span>
                </ng-template>
              </ngx-datatable-column>

<!--              <ngx-datatable-column prop="NroCheque" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Número</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.NroCheque}}" placement="auto"
                    [adaptivePosition]="false">{{row.NroCheque}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="CompensadoDescr" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Compensado</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.CompensadoDescr}}" placement="auto"
                    [adaptivePosition]="false">{{row.CompensadoDescr}}</span>
                </ng-template>
              </ngx-datatable-column> -->

              <ngx-datatable-column prop="RecebeuBoletoDescr" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Boleto</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.RecebeuBoletoDescr}}" placement="auto"
                    [adaptivePosition]="false">{{row.RecebeuBoletoDescr}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="NomeTipoLancamento" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Tipo</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.NomeTipoLancamento}}" placement="auto"
                    [adaptivePosition]="false">{{row.NomeTipoLancamento}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="2" [sortable]="false"
                [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
                <ng-template ngx-datatable-header-template>
                  <span class="ngx-datatable-cabecalho">Ações</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top right"
                    [adaptivePosition]="false" (click)="Edicao(row);">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </span>
                  <span class="iconeTamanho" containerClass="customClass" tooltip="Exclusão" placement="top"
                    style="padding-left: 6% !important;padding-right: 6% !important;" [adaptivePosition]="false"
                    (click)="openModalExcluir(confirmaExclusao, row.CodigoLancamento, row.DescricaoPlanoConta, row.DescExtraPlanoconta );">
                    <i class="fa-solid fa-trash"></i></span>
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>

          </div>

      </div>
    </div>
  </div>

  <app-footer></app-footer>

  <ng-template #confirmaExclusao>
    <div>
      <div class="modal-header modal-header-exclusao">
        <h5 class="modal-title">Confirmação de Exclusão</h5>
      </div>
      <div class="modal-body">
        <p>Confirma a exclusão do orçamento de '{{descricaoLancamento}}'?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ExcluiLancamentoByCodigo()">Sim</button>
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeModal()">Não</button>
      </div>
    </div>
  </ng-template>
