<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-8">

          <div class="card-title-form">Lançamento
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">

              <div class="row">
                <div class="col-lg-6">
                  <label class="form-label" for="formContaPlano">Plano Conta</label>
                  <app-PlanoConta-pesquisa name="codigoPlanoConta" id="codigoPlanoConta"
                    formControlName="codigoPlanoConta" (PlanoContaOut)="changePlanoConta($event);"
                    [CodigoPlanoContaPreench]="this.codigoPlanoConta">
                  </app-PlanoConta-pesquisa>
                  <app-field-error-message [control]="form.get('formContaPlano')"
                    label="plano de contas"></app-field-error-message>
                </div>
                <div class="col-lg-6">
                  <label class="form-label" for="formComplemento">Complemento</label>
                  <input type="text" id="formComplemento" formControlName="formComplemento"
                    [value]="form.get('formComplemento')" name="formComplemento" class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formComplemento')"
                    label="descrição complementar"></app-field-error-message>
                </div>
              </div>

              <div class="row top02">
                <div class="col-lg-6">
                  <label class="form-label" for="formFornecedor">Fornecedor</label>
                  <app-fornecedor-pesquisa id="formFornecedor" formControlName="formFornecedor" name="formFornecedor"
                    [CodigoSocioPreench]="this.codigoFornecedor" (AssociadoOut)="changeFornec($event);">
                  </app-fornecedor-pesquisa>
                  <app-field-error-message [control]="form.get('formContaPlano')"
                    label="plano de contas"></app-field-error-message>
                </div>
                <div class="col-lg-6">
                  <label class="form-label" for="formTipoLancamento">Tipo Lancto</label>
                  <ngx-select-dropdown name="formTipoLancamento" id="formTipoLancamento"
                    formControlName="formTipoLancamento" (change)="changeTipoLanc($event.value)"
                    [config]="configTipoLan" [options]="listaTipoLancamentoOriginal"
                    [(ngModel)]="lancamento.NomeTipoLancamento" class="form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </div>
              </div>

              <div class="row top02">
                <div class="col-lg-4">
                  <label class="form-label" style="width: 100%;">Data Lançamento</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event);"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                    name="formDataLancamento" id="formDataLancamento" formControlName="formDataLancamento"
                    [value]="form.get('formDataLancamento')">
                </div>

                <div class="col-lg-4">
                  <label class="form-label" for="formValorLancamento">Valor</label>
                  <input type="text" id="formValorLancamento" formControlName="formValorLancamento"
                    [value]="form.get('formValorLancamento')" name="formValorLancamento"
                    class="form-control form-control-lg" currencyMask />
                  <app-field-error-message [control]="form.get('formValorLancamento')"
                    label="Valor"></app-field-error-message>
                </div>
                <div class="col-lg-4">
                  <label class="form-label" for="formNumeroParcela">Nro Parcelas</label>
                  <input type="number" id="formNumeroParcela" formControlName="formNumeroParcela"
                    [value]="form.get('formNumeroParcela')" name="formNumeroParcela" (change)="changeRepeticao()"
                    class="form-control form-control-lg" />
                </div>
              </div>

              <div class="row top02">

                <div [ngClass]="lancamento.OrigemLancamento == 'LA' ? 'col-lg-4' : 'col-lg-3'">
                  <label class="form-label" style="width: 100%;">Nro Lançamento</label>
                  <input type="text" id="formNumeroLancamento" formControlName="formNumeroLancamento"
                    [value]="form.get('formNumeroLancamento')" name="formNumeroLancamento"
                    class="form-control form-control-lg" />
                </div>

                <div [ngClass]="lancamento.OrigemLancamento == 'LA' ? 'col-lg-4' : 'col-lg-3'">
                  <label class="form-label" style="width: 100%;" for="formRecebeuBoleto">Recebeu Boleto? </label>
                  <span class="d-inline"> <ui-switch name="formRecebeuBoleto" id="formRecebeuBoleto"
                      formControlName="formRecebeuBoleto" (click)="toggleRecebeuBoleto();"></ui-switch></span>
                </div>
                <div [ngClass]="lancamento.OrigemLancamento == 'LA' ? 'col-lg-4' : 'col-lg-3'">
                  <label class="form-label" style="width: 100%;" for="formCompensado">Compensado? </label>
                  <span class="d-inline"> <ui-switch name="formCompensado" id="formCompensado"
                      formControlName="formCompensado" (click)="toggleCompensado();"></ui-switch></span>
                </div>
                <div class="col-lg-3" *ngIf="lancamento.OrigemLancamento != 'LA'">
                  <label class="form-label" style="width: 100%;" for="formEstoqueAntigo">Estoque Antigo?
                  </label>
                  <span class="d-inline"> <ui-switch name="formEstoqueAntigo" id="formEstoqueAntigo"
                      formControlName="formEstoqueAntigo" (click)="toggleEstoqueAntigo();"></ui-switch></span>
                </div>
              </div>

              <div class="top2">
                <label class="form-label" for="formAssociado">Associado</label>
                <app-associadotodos-pesquisa name="formAssociado" id="formAssociado" formControlName="formAssociado"
                  [CodigoSocioPreench]="this.codigoSocioLancamento" (AssociadoOut)="changeAssociado($event);">
                </app-associadotodos-pesquisa>
                <app-field-error-message [control]="form.get('formAssociado')"
                  label="Associado"></app-field-error-message>
              </div>

              <div class="top2">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary"
                    (click)="SalvaLancamento();">Salvar </button>
                </div>
              </div>

            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<div class="offcanvas offcanvas-top h-100" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="content-wrapper">
    <div class="container-fluid">
      <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
          <div class="col-lg-10">
            <div class="offcanvas-header">
              <div class="card-title-form-menor">Orçamento
                <button type="button" class="float-end btn-close text-primary customSearchButtons"
                  data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
                  placement="bottom" [adaptivePosition]="false"></button>
              </div>
            </div>
            <div class="offcanvas-body">

              <div class="row">
                <!-- Plano de Contas -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPlanoContas">Plano de Contas</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Conta a qual o lançamento está vinculado." />
                </div>
                <!-- Complemento -->
                <div class="col-lg-6">
                  <label class="form-label" for="formComplemento">Complemento</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Descrição complementar da necessidade (até 50 caracteres)." />
                </div>
              </div>

              <div class="row top02">
                <div class="col-lg-6">
                  <label class="form-label" for="formFornecedor">Fornecedor</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Fornecedor caso seja uma compra." />
                </div>
                <div class="col-lg-6">
                  <label class="form-label" for="formTipoLancamento">Tipo Lancto</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Tipo de movimentação financeira deste lançamento." />
                </div>
              </div>

              <div class="row top02">
                <div class="col-lg-4">
                  <label class="form-label" style="width: 100%;">Data Lançamento</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Data deste lançamento." />
                </div>

                <div class="col-lg-4">
                  <label class="form-label" for="formValorLancamento">Valor</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Valor deste lançamento." />
                </div>
                <div class="col-lg-4">
                  <label class="form-label" for="formNumeroParcela">Nro Parcelas</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Número de parcelas deste lançamento (cartão)." />
                </div>
              </div>

              <div class="row top02">

                <div class="col-lg-3">
                  <label class="form-label" style="width: 100%;">Nro Lançamento</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Número de identificação deste lançamento (cheque)." />
                </div>

                <div class="col-lg-3">
                  <label class="form-label" style="width: 100%;" for="formRecebeuBoleto">Recebeu Boleto? </label>
                  <span class="d-block"> <ui-switch></ui-switch>
                    <input disabled class="form-control form-control-lg top02"
                    placeholder="Possui boleto e recebeu?" />
                  </span>
                </div>
                <div class="col-lg-3">
                  <label class="form-label" style="width: 100%;" for="formCompensado">Compensado? </label>
                  <span class="d-block"> <ui-switch></ui-switch>
                    <input disabled class="form-control form-control-lg top02"
                    placeholder="Compensação já ocorreu?" />
                  </span>
                </div>
                <div class="col-lg-3">
                  <label class="form-label" style="width: 100%;" for="formEstoqueAntigo">Estoque Antigo?
                  </label>
                  <span class="d-block"> <ui-switch></ui-switch>
                    <input disabled class="form-control form-control-lg top02"
                    placeholder="Utilizou material já comprado?" />
                  </span>
                </div>
              </div>

              <div class="top2">
                <label class="form-label" for="formAssociado">Associado</label>
                <input disabled class="form-control form-control-lg"
                placeholder="Associado vinculado a este lançamento." />
              </div>

              <div class="w-100 top02"><button type="button" class="btn btn-warning">Cancelar</button><label
                  style="margin-left: 4px; margin-right: 1%;">Retorna para tela de lista de lançamentos.</label></div>
              <div class="w-100 top02"><button type="button" class="btn btn-primary">Salvar</button><label
                  style="margin-left: 4px; margin-right: 1%;">Salva o lançamento selecionado.</label></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
