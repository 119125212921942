import { Utils } from 'src/app/Projeto/Utils/utils';

export class Lancamento {
  CodigoLancamento: number;
  CodigoChave: number;
  CodigoPlanoConta: number;
  DataLancamento: Date;
  ValorLancamento: number;
  DescExtraPlanoconta: string;
  DescricaoPlanoConta: string;
  OrigemLancamento: string;
  CodDescrPlanoConta: string;
  NroCheque: number;
  RecebeuBoleto: string;
  RecebeuBoletoDescr: string;
  Compensado: string;
  CompensadoDescr: string;
  Estoque: boolean;
  CodigoTipoLancamento: number;
  NomeTipoLancamento: string;
  NomeSocioLancamento: string;
  CodigoSocioLancamento: number;
  CodigoCentroCusto: number;
  NomeCentroCusto: string;
  CodigoFornecedor: number;
  NomeFornecedor: string;
  CodigoCriacao: number;
  Repeticao: number;

  constructor() {
    this.CodigoLancamento = 0;
    this.CodigoChave = 0;
    this.CodigoPlanoConta = 0;
    this.ValorLancamento = 0;
    this.DescExtraPlanoconta = '';
    this.DescricaoPlanoConta = '';
    this.OrigemLancamento = '';
    this.CodDescrPlanoConta = '';
    this.NroCheque = 0;
    this.RecebeuBoleto = '';
    this.RecebeuBoletoDescr = '';
    this.Compensado = '';
    this.CompensadoDescr = '';
    this.Estoque = false;
    this.Compensado = '';
    this.CodigoTipoLancamento = 0;
    this.NomeTipoLancamento = '';
    this.CodigoSocioLancamento = 0;
    this.NomeSocioLancamento = '';
    this.CodigoCentroCusto = 0;
    this.NomeCentroCusto = '';
    this.CodigoFornecedor = 0;
    this.NomeFornecedor = '';
    this.CodigoCriacao = 0;
    this.Repeticao = 0;
  }
}

export class FiltroLancamento {
  CodigoPlanoConta: number;
  CodigoTipoLancamento: number;
  CodigoFornecedor: number;

  constructor() {
    this.CodigoPlanoConta = 0;
    this.CodigoTipoLancamento = 0;
    this.CodigoFornecedor = 0;
  }
}
