import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { PlanoConta } from '../models/planoconta';
import { ExcelService } from 'src/app/excel.service';
import PlanoContaService from '../planoconta-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-planoconta-lista',
  templateUrl: './planoconta-lista.component.html',
  styleUrls: ['./planoconta-lista.component.css'],
})
export class PlanoContaListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private PlanoContaService: PlanoContaService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
    public modalService: BsModalService
  ) {}

  planoConta: PlanoConta[] = [];
  planoContaOriginal: PlanoConta[] = [];
  novoPlanoConta: PlanoConta = new PlanoConta();
  DadosExcell: any = [];
  ColunasExcell: any = [];
  mostrarInativos: boolean = true;
  isMinWidth: boolean = true;
  codigoPlanoConta: number = 0;
  nomePlanoConta: string = '';
  modalRef?: BsModalRef;

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaPlanoConta();
  }

  BuscaPlanoConta(): void {
    this.spinner.show();
    try {
      this.PlanoContaService.GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.planoContaOriginal = response.PlanoContaLista;
              this.planoConta = this.planoContaOriginal;
              this.toggleMostrarInativos();
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  filtrarPlanoConta() {
    if (this.mostrarInativos) {
      this.planoConta = this.planoContaOriginal;
    } else {
      this.planoConta = this.planoContaOriginal.filter(
        (item) => item.AtivoPlanoConta
      );
    }
  }

  toggleMostrarInativos() {
    this.mostrarInativos = !this.mostrarInativos;
    this.filtrarPlanoConta();
  }

  Edicao(planoconta: PlanoConta) {
    this.router.navigateByUrl('/administrativo/planocontas/edicao', {
      state: { planoconta: planoconta },
    });
  }

  Adicionar() {
    this.spinner.show();
    this.novoPlanoConta = new PlanoConta();
    this.novoPlanoConta.CodigoPlanoConta = -1;
    this.spinner.hide();
    this.router.navigateByUrl('/administrativo/planocontas/edicao', {
      state: { planoconta: this.novoPlanoConta },
    });
  }

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.planoContaOriginal.filter(function (d: any) {
      return (
        d.CodTextoPlanoConta.toLowerCase().indexOf(val) !== -1 ||
        d.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1 ||
        d.TipoPlanoConta.toLowerCase().indexOf(val) !== -1 ||
        d.ColunaRelatorioDescr.toLowerCase().indexOf(val) !== -1 ||
        d.ContaPai.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    // update the rows
    this.planoConta = temp;
    return true;
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioPlanoContas';
    var Titulo: string = 'Relatório Plano de Contas';
    this.ColunasExcell = [];
    this.ColunasExcell.push('Código');
    this.ColunasExcell.push('Descrição');
    this.ColunasExcell.push('Tipo');
    this.ColunasExcell.push('Conta Pai');
    this.ColunasExcell.push('Coluna Relatório');

    this.DadosExcell = [];
    this.planoConta.forEach((item: PlanoConta) => {
      const varExcell: any = [];
      varExcell.push(item.CodigoPlanoConta);
      varExcell.push(item.DescricaoPlanoConta);
      varExcell.push(item.TipoPlanoContaDescr);
      varExcell.push(item.ContaPai);
      varExcell.push(item.ColunaRelatorioDescr);
      this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
      nomeArquivo,
      Titulo,
      this.ColunasExcell,
      this.DadosExcell
    );
  }

  openModalExcluir(
    templateModal: TemplateRef<any>,
    CodPlanoConta: number,
    nomePlanoConta: string
  ): void {
    this.nomePlanoConta = nomePlanoConta;
    this.codigoPlanoConta = CodPlanoConta;
    this.modalRef = this.modalService.show(templateModal, {
      class: 'modal-dialog-centered',
    });
  }

  ExcluiPlanoContaByCodigo() {
    this.modalRef?.hide();
    this.spinner.show();
    try {
      this.PlanoContaService.ExcluiPlanoContaByCodigo(this.codigoPlanoConta)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            this.spinner.hide();
            this.BuscaPlanoConta();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  closeModal(): void {
    this.modalRef?.hide();
  }
}
