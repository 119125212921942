<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-8">
          <div class="card-title-form">Previsão
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">

              <div class="col-lg-12">
                <label class="form-label" for="formContaPlano">Plano Conta</label>
                <app-PlanoConta-pesquisa name="codigoPlanoConta" id="codigoPlanoConta"
                  formControlName="codigoPlanoConta" (PlanoContaOut)="changePlanoConta($event);"
                  [CodigoPlanoContaPreench]="this.codigoPlanoConta">
                </app-PlanoConta-pesquisa>
                <app-field-error-message [control]="form.get('formContaPlano')"
                  label="plano de contas"></app-field-error-message>
              </div>

              <div class="row top02">

                <div class="col-lg-4">
                  <label class="form-label" style="width: 100%;">Data Previsão</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event , 'dataPrevisao');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                    name="formDataPrevisao" id="formDataPrevisao" formControlName="formDataPrevisao"
                    [value]="form.get('formDataPrevisao')">
                </div>

                <div class="col-lg-4">
                  <label class="form-label" for="formValorPrevisao">Valor</label>
                  <input type="text" id="formValorPrevisao" formControlName="formValorPrevisao"
                    [value]="form.get('formValorPrevisao')" name="formValorPrevisao"
                    class="form-control form-control-lg" currencyMask />
                  <app-field-error-message [control]="form.get('formValorPrevisao')"
                    label="Valor"></app-field-error-message>
                </div>

                <div class="col-lg-4">
                  <label class="form-label" for="formRepeticao">Repetições</label>
                  <input type="number" min="1" max="12" id="formRepeticao" formControlName="formRepeticao"
                    [value]="form.get('formRepeticao')" name="formRepeticao" class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formRepeticao')"
                    label="Repetições"></app-field-error-message>
                </div>
              </div>

              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary"
                    (click)="SalvaPrevisao();">Salvar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-danger"
                    (click)="ExcluirFuturo();">Excluir Lançamentos Futuros</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-top h-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center h-100">
      <div class="col-lg-8">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Previsão
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">

          <div class="row top02">
            <div class="col-lg-12">
              <label class="form-label" for="formCodTextoPlanoConta">Plano Conta</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Plano conta ao qual se refere esta previsão." />
            </div>
          </div>

          <div class="row top02">

            <div class="col-lg-4">
              <label class="form-label" style="width: 100%;">Data Previsão</label>
              <input type="text" disabled class="form-control form-control-lg" placeholder="Data desta previsão." />
            </div>

            <div class="col-lg-4">
              <label class="form-label" for="formValorPrevisao">Valor</label>
              <input type="text" disabled class="form-control form-control-lg" placeholder="Valor desta previsão." />
            </div>

            <div class="col-lg-4">
              <label class="form-label" for="formRepeticao">Repetições</label>
              <textarea type="text" disabled class="form-control form-control-lg" rows="3"
                placeholder="Repete a previsão pelo número indicado pelos meses seguintes."></textarea>
            </div>
          </div>

          <div class="w-100 top02"><button type="button" class="btn btn-warning">Cancelar</button><label
              style="margin-left: 4px; margin-right: 1%;">Retorna para tela de lista de previsões.</label></div>
          <div class="w-100 top02"><button type="button" class="btn btn-primary">Salvar</button><label
              style="margin-left: 4px; margin-right: 1%;">Salva a previsão de acordo com o número de vezes do
              repetir.</label></div>
          <div class="w-100 top02"><button type="button" class="btn btn-danger">Excluir Lançamentos
              Futuro</button><label style="margin-left: 4px; margin-right: 1%;">Exclui as previsões posteriores a atual
              na mesma conta.</label></div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
