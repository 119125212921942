import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ptBrLocale, defineLocale } from 'ngx-bootstrap/chronos';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxNavbarModule } from 'ngx-bootstrap-navbar';
import {
  NgxCurrencyDirective,
  provideEnvironmentNgxCurrency,
  NgxCurrencyInputMode,
} from 'ngx-currency';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
} from 'ngx-mask';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FileUploadModule } from '@iplab/ngx-file-upload';

//Confirm modal component
import { ConfirmModalComponent } from './message/confirm-modal/confirm-modal.component';

//widget de mensagem de html
import { FieldErrorMessageComponent } from './field-error-message/field-error-message.component';

//formulário autenticacão
import { LoginComponent } from '../Projeto/Autenticacao/Login/Login.component';

//formulário projeto
import { Error404Component } from './Auxiliares/error-404/error-404.component';
import { HeaderComponent } from './Auxiliares/Cabecalho/header.component';
import { FooterComponent } from './Auxiliares/Rodape/footer.component';
import { HomeComponent } from './Auxiliares/Home/home.component';

//formulário sistema
import { SalasListaComponent } from '../Sistema/salas/salas-lista/salas-lista.component';
import { SalasOcupacaoComponent } from '../Sistema/salas/salas-ocupacao/salas-ocupacao.component';
import { SalasEdicaoComponent } from '../Sistema/salas/salas-edicao/salas-edicao.component';
import { SalasOcupacaoExibeComponent } from '../Sistema/salas/salas-ocupacao-exibe/salas-ocupacao-exibe.component';
import { AssociadosListaComponent } from '../Sistema/associados/associados-lista/associados-lista.component';
import { AssociadosEdicaoComponent } from '../Sistema/associados/associados-edicao/associados-edicao.component';
import { AssociadosEdicaoPrioComponent } from '../Sistema/associados/associados-edicao-prio/associados-edicao-prio.component';
import { AssociadosEdicaoEndComponent } from '../Sistema/associados/associados-edicao-end/associados-edicao-end.component';
import { AssociadosEdicaoCategComponent } from '../Sistema/associados/associados-edicao-categ/associados-edicao-categ.component';
import { AssociadosEdicaoDispComponent } from '../Sistema/associados/associados-edicao-disponibilidade/associados-edicao-disponibilidade.component';
import { AssociadosListaCategoriaComponent } from '../Sistema/associados/associados-lista-categoria/associados-lista-categoria.component';
import { AssociadosListaMensalidadeComponent } from '../Sistema/associados/associados-lista-mensalidade/associados-lista-mensalidade.component';
import { AssociadosEdicaoMensalidadeComponent } from '../Sistema/associados/associados-edicao-mensalidade/associados-edicao-mensalidade.component';
import { AreaAtuacaoListaComponent } from '../Sistema/areaatuacao/areaatuacao-lista/areaatuacao-lista.component';
import { AreaAtuacaoEdicaoComponent } from '../Sistema/areaatuacao/areaatuacao-edicao/areaatuacao-edicao.component';
import { CategoriaListaComponent } from '../Sistema/categoria/categoria-lista/categoria-lista.component';
import { CategoriaEdicaoComponent } from '../Sistema/categoria/categoria-edicao/categoria-edicao.component';
import { PlanoContaListaComponent } from '../Sistema/planoconta/planoconta-lista/planoconta-lista.component';
import { PrevisaoListaComponent } from '../Sistema/previsao/previsao-lista/previsao-lista.component';
import { PrevisaoEdicaoComponent } from '../Sistema/previsao/previsao-edicao/previsao-edicao.component';
import { OrcamentoListaComponent } from '../Sistema/orcamento/orcamento-lista/orcamento-lista.component';
import { OrcamentoEdicaoComponent } from '../Sistema/orcamento/orcamento-edicao/orcamento-edicao.component';
import { LancamentoListaComponent } from '../Sistema/lancamento/lancamento-lista/lancamento-lista.component';
import { LancamentoEdicaoComponent } from '../Sistema/lancamento/lancamento-edicao/lancamento-edicao.component';
import { AtividadeListaComponent } from '../Sistema/atividades/atividade-lista/atividade-lista.component';
import { AtividadeEdicaoComponent } from '../Sistema/atividades/atividade-edicao/atividade-edicao.component';
import { AreaAtuacaoPesquisaComponent } from '../Sistema/forms-modais-pesquisa/area-pesquisa/area-atuacao-pesquisa.component';
import { CategoriaPesquisaComponent } from '../Sistema/forms-modais-pesquisa/categoria-pesquisa/categoria-pesquisa.component';
import { PlanoContaPesquisaComponent } from '../Sistema/forms-modais-pesquisa/planoconta-pesquisa/planoconta-pesquisa.component';
import { AssociadoPesquisaComponent } from '../Sistema/forms-modais-pesquisa/associado-pesquisa/associado-pesquisa.component';
import { AssociadoTodosPesquisaComponent } from '../Sistema/forms-modais-pesquisa/associadotodos-pesquisa/associadotodos-pesquisa.component';
import { EstagiarioPesquisaComponent } from '../Sistema/forms-modais-pesquisa/estagiario-pesquisa/estagiario-pesquisa.component';
import { TerapeutaPesquisaComponent } from '../Sistema/forms-modais-pesquisa/terapeuta-pesquisa/terapeuta-pesquisa.component';
import { PacientePesquisaComponent } from '../Sistema/forms-modais-pesquisa/paciente-pesquisa/paciente-pesquisa.component';
import { FornecedorPesquisaComponent } from '../Sistema/forms-modais-pesquisa/fornecedor-pesquisa/fornecedor-pesquisa.component';
import { RelatoriosComponent } from '../Sistema/Relatorios/relatorios.component';
import { PlanoContaEdicaoComponent } from '../Sistema/planoconta/planoconta-edicao/planoconta-edicao.component';
import { PrevisaoPesquisaComponent } from '../Sistema/forms-modais-pesquisa/previsao-pesquisa/previsao-pesquisa.component';
import { RotinasComponent } from '../Sistema/rotinas/rotinas.component';
import { DashboardComponent } from '../Sistema/dashboard/dashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ProfissionalListaComponent } from '../Sistema/atividades/profissional/profissional-lista/profissional-lista.component';
import { InscricaoListaComponent } from '../Sistema/atividades/inscricao/inscricao-lista/inscricao-lista.component';
import { SaldoListaComponent } from '../Sistema/saldo/saldo-lista/saldo-lista.component';
import { SaldoEdicaoComponent } from '../Sistema/saldo/saldo-edicao/saldo-edicao.component';

// Define o Locale da aplicação. Válido somente para o ngx-bootstrap.
// Não é aplicado globalmente.
defineLocale('pt-br', ptBrLocale);

@NgModule({
  declarations: [
    LoginComponent,
    Error404Component,
    HeaderComponent,
    FooterComponent,
    ConfirmModalComponent,
    FieldErrorMessageComponent,
    HomeComponent,
    SalasListaComponent,
    SalasOcupacaoComponent,
    SalasEdicaoComponent,
    SalasOcupacaoExibeComponent,
    AssociadosListaComponent,
    AssociadosEdicaoComponent,
    AssociadosEdicaoPrioComponent,
    AssociadosEdicaoEndComponent,
    AreaAtuacaoListaComponent,
    AreaAtuacaoEdicaoComponent,
    AssociadosEdicaoCategComponent,
    AssociadosEdicaoDispComponent,
    AreaAtuacaoPesquisaComponent,
    CategoriaListaComponent,
    CategoriaEdicaoComponent,
    PlanoContaListaComponent,
    AssociadoPesquisaComponent,
    AssociadoTodosPesquisaComponent,
    AssociadosListaCategoriaComponent,
    AssociadosListaMensalidadeComponent,
    AssociadosEdicaoMensalidadeComponent,
    EstagiarioPesquisaComponent,
    TerapeutaPesquisaComponent,
    PacientePesquisaComponent,
    FornecedorPesquisaComponent,
    CategoriaPesquisaComponent,
    RelatoriosComponent,
    PlanoContaPesquisaComponent,
    PlanoContaEdicaoComponent,
    RotinasComponent,
    PrevisaoListaComponent,
    PrevisaoPesquisaComponent,
    PrevisaoEdicaoComponent,
    PlanoContaListaComponent,
    PlanoContaEdicaoComponent,
    PlanoContaPesquisaComponent,
    OrcamentoListaComponent,
    OrcamentoEdicaoComponent,
    LancamentoListaComponent,
    LancamentoEdicaoComponent,
    AtividadeListaComponent,
    AtividadeEdicaoComponent,
    ProfissionalListaComponent,
    InscricaoListaComponent,
    DashboardComponent,
    SaldoListaComponent,
    SaldoEdicaoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule,
    TimepickerModule,
    NgxDatatableModule,
    UiSwitchModule.forRoot({
      size: 'large',
      color: '#3fb618',
      switchColor: '#efefef',
      defaultBgColor: '#ff0039',
      defaultBoColor: 'none',
      checkedLabel: 'Sim',
      uncheckedLabel: 'Não',
      checkedTextColor: '#FFF',
      uncheckedTextColor: '#FFF',
    }),
    NgxSpinnerModule,
    NgxNavbarModule,
    NgxCurrencyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    SelectDropDownModule,
    FileUploadModule,
    NgxChartsModule,
  ],
  exports: [
    LoginComponent,
    Error404Component,
    HeaderComponent,
    FooterComponent,
    ConfirmModalComponent,
    FieldErrorMessageComponent,
    HomeComponent,
    SalasListaComponent,
    SalasOcupacaoComponent,
    SalasEdicaoComponent,
    SalasOcupacaoExibeComponent,
    AssociadosListaComponent,
    AssociadosEdicaoComponent,
    AssociadosEdicaoPrioComponent,
    AssociadosEdicaoEndComponent,
    AssociadosEdicaoCategComponent,
    AssociadosEdicaoDispComponent,
    AreaAtuacaoListaComponent,
    AreaAtuacaoEdicaoComponent,
    AreaAtuacaoPesquisaComponent,
    CategoriaListaComponent,
    CategoriaEdicaoComponent,
    PlanoContaListaComponent,
    AssociadoPesquisaComponent,
    AssociadoTodosPesquisaComponent,
    AssociadosListaCategoriaComponent,
    AssociadosListaMensalidadeComponent,
    AssociadosEdicaoMensalidadeComponent,
    EstagiarioPesquisaComponent,
    TerapeutaPesquisaComponent,
    PacientePesquisaComponent,
    CategoriaPesquisaComponent,
    FornecedorPesquisaComponent,
    RelatoriosComponent,
    PlanoContaPesquisaComponent,
    PlanoContaEdicaoComponent,
    RotinasComponent,
    PrevisaoListaComponent,
    PrevisaoPesquisaComponent,
    PrevisaoEdicaoComponent,
    OrcamentoListaComponent,
    OrcamentoEdicaoComponent,
    LancamentoListaComponent,
    LancamentoEdicaoComponent,
    AtividadeListaComponent,
    AtividadeEdicaoComponent,
    ProfissionalListaComponent,
    InscricaoListaComponent,
    SaldoListaComponent,
    SaldoEdicaoComponent,
    ReactiveFormsModule,
    TooltipModule,
    AccordionModule,
    BsDatepickerModule,
    ModalModule,
    CollapseModule,
    TabsModule,
    TimepickerModule,
    NgxDatatableModule,
    UiSwitchModule,
    NgxSpinnerModule,
    NgxNavbarModule,
    NgxCurrencyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    SelectDropDownModule,
    FileUploadModule,
    DashboardComponent,
    NgxChartsModule,
  ],
  providers: [
    provideEnvironmentNgxMask(),
    provideEnvironmentNgxCurrency({
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: 2,
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    }),
  ],
})
export class ProjectModule {
  constructor(private localeService: BsLocaleService) {
    this.setDatepickerLanguage();
  }

  setDatepickerLanguage() {
    ptBrLocale.invalidDate = 'Data Inválida';
    defineLocale('pt-br', ptBrLocale);
    this.localeService.use('pt-br');
  }
}
