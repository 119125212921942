<app-header></app-header>

<body>
  <div class="content-wrapper">
    <div class="container-fluid">

      <!-- <div class="card-title-form">Ocupação das Salas</div> -->
      <div class="card-body-form">

        <ul class="nav nav-tabs">
          <li class="nav-item d-flex">
            <a class="nav-link active" id="S1" data-bs-toggle="tab" role="tab" aria-controls="S1"
              aria-selected="true" [class.active]="isActiveAba('S1')" (click)="setAba('S1')">Sala 01</a>
          </li>
          <li class="nav-item d-flex">
            <a class="nav-link active" id="S2" data-bs-toggle="tab" role="tab" aria-controls="S2"
              aria-selected="true" [class.active]="isActiveAba('S2')" (click)="setAba('S2')">Sala 02</a>
          </li>
          <li class="nav-item d-flex">
            <a class="nav-link active" id="S3" data-bs-toggle="tab" role="tab" aria-controls="S3"
              aria-selected="true" [class.active]="isActiveAba('S3')" (click)="setAba('S3')">Sala 03</a>
          </li>
          <li class="nav-item d-flex">
            <a class="nav-link active" id="S4" data-bs-toggle="tab" role="tab" aria-controls="S4"
              aria-selected="true" [class.active]="isActiveAba('S4')" (click)="setAba('S4')">Sala 04</a>
          </li>
          <li class="nav-item d-flex">
            <a class="nav-link active" id="S9" data-bs-toggle="tab" role="tab" aria-controls="S9"
              aria-selected="true" [class.active]="isActiveAba('S9')" (click)="setAba('S9')">Sala OnLine</a>
          </li>
        </ul>
        <ng-container>
          <div class="tab-content top02" id="myTabContent" [ngSwitch]="aba">
            <app-sala-ocupacao-exibe [CodigoSala]="1" *ngSwitchCase="'S1'"></app-sala-ocupacao-exibe>
            <app-sala-ocupacao-exibe [CodigoSala]="2" *ngSwitchCase="'S2'"></app-sala-ocupacao-exibe>
            <app-sala-ocupacao-exibe [CodigoSala]="3" *ngSwitchCase="'S3'"></app-sala-ocupacao-exibe>
            <app-sala-ocupacao-exibe [CodigoSala]="4" *ngSwitchCase="'S4'"></app-sala-ocupacao-exibe>
            <app-sala-ocupacao-exibe [CodigoSala]="9" *ngSwitchCase="'S9'"></app-sala-ocupacao-exibe>
          </div>
        </ng-container>

      </div>

    </div>
  </div>
</body>

<app-footer></app-footer>
