export class PlanoConta {
  CodigoPlanoConta: number;
  CodTextoPlanoConta: string;
  DescricaoPlanoConta: string;
  TipoPlanoConta: string;
  CodigoPlanoContaPai: number;
  CodDescrPlanoConta: string;
  AtivoPlanoConta: boolean;
  SomaRelatorio: boolean;
  ColunaRelatorio: string;
  ContaPai: string;
  TipoPlanoContaDescr: string;
  ColunaRelatorioDescr: string;

  constructor() {
    this.CodigoPlanoConta = 0;
    this.CodTextoPlanoConta = '';
    this.DescricaoPlanoConta = '';
    this.TipoPlanoConta = '';
    this.CodigoPlanoContaPai = 0;
    this.CodDescrPlanoConta = "";
    this.ContaPai = '';
    this.AtivoPlanoConta = true;
    this.SomaRelatorio = true;
    this.TipoPlanoContaDescr = '';
    this.ColunaRelatorioDescr = '';
  }

}
