import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { FiltroLancamento, Lancamento } from '../models/lancamento';
import { ExcelService } from 'src/app/excel.service';
import LancamentoService from '../lancamento.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlanoConta } from '../../planoconta/models/planoconta';
import { SocioResumo } from '../../associados/models/associados';
import { TipoLancamento } from '../models/tipoLancamento';

@Component({
  selector: 'app-lancamento-lista',
  templateUrl: './lancamento-lista.component.html',
  styleUrls: ['./lancamento-lista.component.css'],
})
export class LancamentoListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private lancamentoService: LancamentoService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
    public modalService: BsModalService
  ) {}

  lancamento: Lancamento[] = [];
  filtroLancamento: FiltroLancamento = new FiltroLancamento();
  lancamentoOriginal: Lancamento[] = [];
  novaLancamento: Lancamento = new Lancamento();
  DadosExcell: any = [];
  ColunasExcell: any = [];

  codigoLancamento: number = 0;
  descricaoLancamento: string = '';
  modalRef?: BsModalRef;
  codigoPlanoConta: number = 0;
  codigoFornecedor: number = 0;
  codigoTipoLancamento: number = 0;
  listaTipoLancamentoOriginal: TipoLancamento[] = [];

  configTipoLan = {
    search: true,
    placeholder: 'Selecione o tipo lançamento,', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'NomeTipoLancamento', //TROCA CAMPO DESCRIÇÃO
    height: '220px',
    limitTo: 0,
  };

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaLancamentos();
    this.BuscaTipoLancamento();
  }

  BuscaLancamentos(): void {
    this.spinner.show();
    this.lancamentoOriginal = [];
    try {
      this.filtroLancamento = new FiltroLancamento();
      this.filtroLancamento.CodigoFornecedor = this.codigoFornecedor;
      this.filtroLancamento.CodigoPlanoConta = this.codigoPlanoConta;
      this.filtroLancamento.CodigoTipoLancamento = this.codigoTipoLancamento;

      this.lancamentoService
        .GetListaGeral(this.filtroLancamento)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.lancamentoOriginal = response.LancamentoLista;
            }
            this.lancamento = this.lancamentoOriginal;
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaTipoLancamento(): void {
    try {
      this.lancamentoService
        .GetTipoLancamento()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.listaTipoLancamentoOriginal = response.TipoLancamentoLista;
            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  changePlanoConta($event: PlanoConta) {
    this.codigoPlanoConta = $event.CodigoPlanoConta;
  }

  changeFornec($event: SocioResumo) {
    this.codigoFornecedor = $event.CodigoSocio;
  }

  changeTipoLanc($event: TipoLancamento) {
    this.codigoTipoLancamento = $event.CodigoTipoLancamento;
  }

  Edicao(lancamento: Lancamento) {
    this.router.navigateByUrl('/administrativo/lancamentos/edicao', {
      state: { lancamento: lancamento },
    });
  }

  Adicionar() {
    this.novaLancamento = new Lancamento();
    this.novaLancamento.CodigoLancamento = -1;
    this.novaLancamento.OrigemLancamento = 'LA';
    this.novaLancamento.DataLancamento = new Date();
    this.novaLancamento.CodigoChave = 0;
    this.novaLancamento.RecebeuBoleto = 'N';
    this.novaLancamento.Compensado = 'N';
    this.novaLancamento.Estoque = false;
    this.novaLancamento.Repeticao = 1;
    this.router.navigateByUrl('/administrativo/lancamentos/edicao', {
      state: { lancamento: this.novaLancamento },
    });
  }

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    const temp = this.lancamentoOriginal.filter(function (d: any) {
      return (
        d.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1 ||
        d.NomeTipoLancamento.toLowerCase().indexOf(val) !== -1 ||
        d.NomeFornecedor.toLowerCase().indexOf(val) !== -1 ||
        d.DescExtraPlanoconta.toLowerCase().indexOf(val) !== -1 ||
        d.NomeTipoLancamento.toLowerCase().indexOf(val) !== -1 ||
        Utils.getDateddMMyyy(d.DataLancamento).indexOf(val) !== -1 ||
        !val
      );
    });
    // update the rows
    this.lancamento = temp;
    return true;
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioLancamento';
    var Titulo: string = 'Relatório de Lançamentos';
    this.ColunasExcell = [];
    this.ColunasExcell.push('Plano Conta');
    this.ColunasExcell.push('Descrição Complementar');
    this.ColunasExcell.push('Nome Fornecedor');
    this.ColunasExcell.push('Data');
    this.ColunasExcell.push('Valor');
    this.ColunasExcell.push('Número');
    this.ColunasExcell.push('Compensado');
    this.ColunasExcell.push('Boleto');
    this.ColunasExcell.push('Tipo Lançamento');
    this.DadosExcell = [];
    this.lancamento.forEach((item: Lancamento) => {
      const varExcell: any = [];
      varExcell.push(item.DescricaoPlanoConta);
      varExcell.push(item.DescExtraPlanoconta);
      varExcell.push(item.NomeFornecedor);
      varExcell.push(Utils.getDateddMMyyy(item.DataLancamento));
      varExcell.push(Utils.converterValorMoeda(item.ValorLancamento));
      varExcell.push(item.NroCheque);
      varExcell.push(item.CompensadoDescr);
      varExcell.push(item.RecebeuBoletoDescr);
      varExcell.push(item.NomeTipoLancamento);
      this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
      nomeArquivo,
      Titulo,
      this.ColunasExcell,
      this.DadosExcell
    );
  }

  openModalExcluir(
    templateModal: TemplateRef<any>,
    CodCompra: number,
    DescrPlanoConta: string,
    DescrExtraPlanoConta: string
  ): void {
    this.codigoLancamento = CodCompra;
    this.descricaoLancamento = DescrPlanoConta + ' ' + DescrExtraPlanoConta;
    this.modalRef = this.modalService.show(templateModal, {
      class: 'modal-dialog-centered',
    });
  }

  ExcluiLancamentoByCodigo() {
    this.modalRef?.hide();
    this.spinner.show();
    try {
      this.lancamentoService
        .ExcluiLancamentoByCodigo(this.codigoLancamento)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            this.spinner.hide();
            this.BuscaLancamentos();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  closeModal(): void {
    this.modalRef?.hide();
  }
}
