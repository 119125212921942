<header>
<nav ngxNavbarDynamicExpand class="navbar navbar-expand-lg bg-primary">

    <a class="navbar-brand" routerLink="/home">
      <img src="../../../../assets/img/logosig.png" height="45">
    </a>
    <button class="navbar-toggler" type="button" (click)="collapse.toggle()" aria-controls="main-nav"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="
          navbar-toggler-icon
          d-flex
          align-items-center
          justify-content-center
        ">
      </span>
    </button>
    <ngx-navbar-collapse id="main-nav" #collapse="ngxNavbarCollapse">

      <ul class="navbar-nav me-auto">
        <li class="nav-item dropdown" data-bs-theme="light">
          <a class="nav-link primary dropdown-toggle" id="linkSalas" style="cursor: pointer;"
            data-bs-toggle="dropdown">Salas</a>
          <div class="dropdown-menu">
            <a routerLink="/salas/cadastro" class="dropdown-item">Cadastro</a>
            <a routerLink="/salas/ocupacao" class="dropdown-item">Ocupação</a>
          </div>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a routerLink="/cadastrogeral/lista" class="nav-link primary scroll-page">Cadastro Geral</a>
        </li>
        <li class="nav-item dropdown" data-bs-theme="light">
          <a class="nav-link primary dropdown-toggle" id="linkAdmin" style="cursor: pointer;"
            data-bs-toggle="dropdown">Administrativo</a>
          <div class="dropdown-menu">
            <a routerLink="/administrativo/areaatuacao" class="dropdown-item">Área Atuação</a>
            <a routerLink="/administrativo/categoria" class="dropdown-item">Categoria</a>
            <a routerLink="/administrativo/planocontas" class="dropdown-item">Plano de Contas</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/administrativo/previsoes" class="dropdown-item">Previsões</a>
            <a routerLink="/administrativo/orcamentos" class="dropdown-item">Orçamentos</a>
            <a routerLink="/administrativo/lancamentos" class="dropdown-item">Lançamentos</a>
            <a routerLink="/administrativo/saldos" class="dropdown-item">Saldo do Dia</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/administrativo/rotinas" class="dropdown-item">Rotinas</a>
            <a [routerLink]="['/atividades']" [queryParams]="{ tipo: 'Atividades Administrativas' }" class="dropdown-item">Atividades Administrativas</a>
          </div>
        </li>
        <li class="nav-item dropdown" data-bs-theme="light">
          <a class="nav-link primary dropdown-toggle" id="linkClinica" style="cursor: pointer;"
            data-bs-toggle="dropdown">Clínica</a>
          <div class="dropdown-menu">
            <a routerLink="/clinica/feriado" class="dropdown-item">Feriados</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/clinica/consultas" class="dropdown-item">Consultas</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/clinica/avaliacaofila" class="dropdown-item">Avaliação Fila</a>
            <!-- <a routerLink="/clinica/avaliacaodatahora" class="dropdown-item">Avaliação Data/Hora</a> -->
            <a routerLink="/clinica/disponibilidade" class="dropdown-item">Disponibilidade
              Horários
              Terapeutas e Estagiários</a>
            <div class="dropdown-divider"></div>
            <a [routerLink]="['/atividades']" [queryParams]="{ tipo: 'Atividades Clínicas' }" class="dropdown-item">Atividades Clínicas</a>
          </div>
        </li>
        <li class="nav-item dropdown" data-bs-theme="light">
          <a class="nav-link primary dropdown-toggle" id="linkEnsino" style="cursor: pointer;"
            data-bs-toggle="dropdown">Ensino</a>
          <div class="dropdown-menu">
            <a routerLink="/ensino/estrutura" class="dropdown-item">Estrutura do Ensino</a>
            <a routerLink="/ensino/montaseminario" class="dropdown-item">Criação de Seminários</a>
            <a routerLink="/ensino/formacao" class="dropdown-item">Consulta Formação</a>
            <a routerLink="/ensino/seminarios" class="dropdown-item">Seminários</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/ensino/trabalhoanual" class="dropdown-item">Trabalho Anual</a>
            <a routerLink="/ensino/trabalhoconclusao" class="dropdown-item">Trabalho de
              Conclusão</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/ensino/supervisaoadulto" class="dropdown-item">Supervisão Adulto</a>
            <a routerLink="/ensino/supervisaoinfantil" class="dropdown-item">Supervisão
              Infantil</a>
            <div class="dropdown-divider"></div>
            <a [routerLink]="['/atividades']" [queryParams]="{ tipo: 'Atividades Ensino' }" class="dropdown-item">Atividades Ensino</a>
            <a [routerLink]="['/atividades']" [queryParams]="{ tipo: 'Atividades Ensino - Estágio' }" class="dropdown-item">Atividades Ensino - Estágio</a>
            <a [routerLink]="['/atividades']" [queryParams]="{ tipo: 'Atividades Ensino - Exclusivo Sócios' }" class="dropdown-item">Atividades Ensino - Grupos Discussão</a>
          </div>
        </li>
        <li class="nav-item dropdown" data-bs-theme="light">
          <a class="nav-link primary dropdown-toggle" id="linkCientifico" style="cursor: pointer;"
            data-bs-toggle="dropdown">Científico</a>
          <div class="dropdown-menu">
            <a routerLink="/cientifico/tipoatividade" class="dropdown-item">Tipos de
              Atividade</a>
              <a [routerLink]="['/atividades']" [queryParams]="{ tipo: 'Atividades Científicas' }" class="dropdown-item">Atividades Científicas</a>
          </div>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a routerLink="/relatorios" class="nav-link primary scroll-page">Relatórios</a>
        </li>
        <li class="nav-item dropdown" data-bs-theme="light">
          <a class="nav-link primary dropdown-toggle show" id="linkAssoc" style="cursor: pointer;" role="button"
            aria-haspopup="true" aria-expanded="false" data-bs-toggle="dropdown">Associado</a>
          <div class="dropdown-menu" data-bs-popper="static">
            <a routerLink="/cadastro" class="dropdown-item">Cadastro</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/associado/agenda" class="dropdown-item">Agenda</a>
            <a routerLink="/associado/consultas" class="dropdown-item">Consultas</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/associado/avaliacaoseminario" class="dropdown-item">Avaliação
              Seminário</a>
            <a routerLink="/associado/avaliacaotrabalhoanual" class="dropdown-item">Avaliação
              Trabalho
              Anual</a>
            <a routerLink="/associado/avaliacaotrabalhoconclusao" class="dropdown-item">Avaliação
              Trabalho de Conclusão</a>
            <a routerLink="/associado/avaliacaosupervisao" class="dropdown-item">Avaliação
              Supervisão</a>
            <a routerLink="/associado/avaliacaococoordenacao" class="dropdown-item">Avaliação
              Co-Coordenação</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav ms-md-auto">
        <li class="nav-item" routerLinkActive="active">
          <a (click)="onLogout()" class="nav-link primary scroll-page">Sair</a>
        </li>
      </ul>

    </ngx-navbar-collapse>

</nav>
</header>
