import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetPlanoContaLista } from './models/ret-planoconta';

import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';
import { PlanoConta } from './models/planoconta';

@Injectable({
  providedIn: 'root',
})
export default class PlanoContaService {
  private readonly baseURL: string = '';
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private authStorageService: AuthStorageService
  ) {
    this.baseURL = `${environment.PlanoContaWS}`;
  }

  GetListaGeral(): Observable<RetPlanoContaLista> {
    const url = `${this.baseURL}/ListaGeral`;
    return this.httpClient.get<RetPlanoContaLista>(url).pipe(
      take(1),
      map((response: RetPlanoContaLista) => {
        return response;
      })
    );
  }

  SalvaPlanoConta(planoConta: PlanoConta): Observable<RetError> {
    let bodyString = JSON.stringify(planoConta);

    const url = `${this.baseURL}/SalvaPlanoContas`;
    return this.httpClient.post<RetError>(url, bodyString).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );
  }

  ExcluiPlanoContaByCodigo(CodigoPlanoConta: number): Observable<RetError> {
    const params = new HttpParams().set('CodigoPlanoConta', CodigoPlanoConta);

    const url = `${this.baseURL}/ExcluiPlanoContaByCodigo`;
    return this.httpClient.get<RetError>(url, { params: params }).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );
  }

}
