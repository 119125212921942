import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Categoria } from '../../categoria/models/categoria';
import { CategoriaService } from '../../categoria/categoria.service';

@Component({
  selector: 'app-categoria-pesquisa',
  templateUrl: './categoria-pesquisa.component.html',
})
export class CategoriaPesquisaComponent implements OnInit {
  //#region Variables

  @Input() CodigoCategoria: number = 0;

  @Input() CodigoCategoriaPreench: number = 0;
  @Input() disabled: boolean = false;

  @Output() CategoriaOut: EventEmitter<Categoria> =
    new EventEmitter<Categoria>();

  CategoriaObj: Categoria = new Categoria();
  private listaCategoriaOrigem: Categoria[] = [];
  listaCategoriaTela: Categoria[] = [];
  isLoading: boolean = true;

  my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: ' registros',
  };

  constructor(
    public bsModalService: BsModalService,
    private projectUtilservice: UtilsService,
    public service: CategoriaService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.CodigoCategoriaPreench != 0) {
      if (
        changes['CodigoCategoriaPreench']?.currentValue !=
        changes['CodigoCategoriaPreench']?.previousValue
      ) {
        this.GetListaGeral();
        return;
      } else {
        this.CategoriaObj = new Categoria();
        return;
      }
    }

    this.CategoriaObj = new Categoria();
  }

  GetListaGeral() {
    this.isLoading = true;
    this.service
      .GetListaGeral()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.listaCategoriaTela = response.CategoriaLista;
          this.listaCategoriaOrigem = response.CategoriaLista;
          this.isLoading = false;

          if (
            this.CodigoCategoriaPreench != 0 &&
            this.CodigoCategoriaPreench &&
            this.CodigoCategoriaPreench !=
            this.CategoriaObj.CodigoCategoria &&
            this.CategoriaObj.CodigoCategoria == 0
          ) {
            this.CategoriaObj = this.listaCategoriaOrigem.find(
              (predicate) =>
                predicate.CodigoCategoria == this.CodigoCategoriaPreench
            )!;
            this.CategoriaOut.emit(this.CategoriaObj);
          }
        },
        error: (error) => {
          this.projectUtilservice.showHttpError(error);
          this.isLoading = false;
        },
      });
  }

  limpaCampo() {
    this.CategoriaObj = new Categoria();
    this.CodigoCategoria = 0;
    this.CodigoCategoriaPreench = 0;
    this.addNewItem(this.CategoriaObj);
  }

  private change($event: any) {
    this.isLoading = true;
    this.CategoriaObj.CodigoCategoria = $event.CodigoCategoria;
    this.CategoriaObj.DescricaoCategoria =  $event.DescricaoCategoria;
    this.CategoriaObj.PagaMensalidade =  $event.PagaMensalidade;
    this.CategoriaObj.ValorMensalidade =  $event.ValorMensalidade;
    this.CodigoCategoria = $event.CodigoCategoria;
    this.CategoriaOut.emit($event);
  }

  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.listaCategoriaOrigem.filter((elem) => {
      return elem.DescricaoCategoria.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.listaCategoriaTela = temp;
    return true;
  }

  addNewItem(value: Categoria) {
    this.change(value);
    this.bsModalService.hide('CategoriaPopUpComponent');
  }


}
