export class Utils {
  // Obtém a mensagem de erro de uma requisição http
  static getHttpErrorMessage(error: any): string {
    let errorMessage: string = '';

    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      if (error.status) {
        if (error.error) {
          if (error.error.ErrorMessage) {
            errorMessage = `${error.error.ErrorMessage}<br><br>${error.status} - ${error.statusText}`;
          } else {
            errorMessage = `${error.error.Message}<br><br>${error.status} - ${error.statusText}`;
          }
        } else {
          errorMessage = error.message;
        }
      } else {
        errorMessage = error.message;
      }
    }

    return errorMessage;
  }

  /**
   * Tenta realizar um parse de uma data com os seguintes valores: dd/MM/yyyy HH:mm
   *
   * @param value String que contém a data formatada em pt-br
   * @param dateSeparator Separador da data.
   * @returns
   */
  static parseDateTime(
    value: string,
    dateSeparator: string = '/'
  ): Date | undefined {
    let usDate = '';

    value = value.trim();

    let dateAndTime: string[] = value.split(' ');

    // Possui pelo menos 5 caracteres: 1/1/1
    if (value.length >= 5) {
      let dateSeparated: string[] = dateAndTime[0].split(dateSeparator);

      if (dateSeparated.length !== 3) {
        return;
      }

      if (dateSeparated[2].length > 2) {
        dateSeparated[2] = `${dateSeparated[2].charAt(
          0
        )}${dateSeparated[2].charAt(1)}`.trim();
      }

      usDate = `${dateSeparated[1]}/${dateSeparated[0]}/${dateSeparated[2]}`;
    }

    if (dateAndTime.length === 2) {
      usDate += ` ${dateAndTime[1]}`;
    }

    let expectedDate: number = Date.parse(usDate);

    if (!Number.isNaN(expectedDate) || expectedDate < 0) {
      return;
    }

    return new Date(expectedDate);
  }

  // Obtém a Data local
  static getDateLocalTime(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  // Obtém a Data local no Padrão Iso
  static getDateLocalTimeISO(date: Date): string {
    return this.getDateLocalTime(date).toISOString().replace('.000Z', '');
  }

  static getDatePickerDate(str: String): Date {
    const [dateComponents, timeComponents] = str.split('T');
    const [ano, mes, dia] = dateComponents.split('-');
    const [hora, minuto, segundo] = timeComponents.split(':');
    const date = new Date(Number(ano), Number(mes), Number(dia));

    return date;
  }

  static AdicionaDias(data: Date, days: number): Date {
    const find = new Date(Number(data));

    find.setDate(data.getDate() + days);

    return find;
  }

  static DiferencaDias(dataInicial: Date, dataFinal: Date): number {
    var Time = dataFinal.getTime() - dataInicial.getTime();

    return Time / (1000 * 3600 * 24);
  }

  static DiferencaMeses(dataInicial: Date, dataFinal: Date): number {
    var meses = (dataFinal.getFullYear() - dataInicial.getFullYear()) * 12;
    meses -= dataInicial.getMonth();
    meses += dataFinal.getMonth();

    return meses <= 0 ? 0 : meses;
  }

  //Obtém a Data UTC com hora zerada
  static getDateUTC(date: Date): Date {
    //codigo original
    //return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
    let dataSaida: Date;

    try {
      dataSaida = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0
      );
    } catch (error) {
      //Quando date chega neste formato: 2022-06-20T00:00:00
      //tento pegar ano/mes/dia direto
      //Date(DtIniFer.getUTCFullYear(), DtIniFer.getUTCMonth(), DtIniFer.getUTCDate(), 0 , 0 , 0);
      //e não funciona
      const dia: string = date.toString().substring(8, 10);
      const mes: string = date.toString().substring(5, 7);
      const ano: string = date.toString().substring(0, 4);
      dataSaida = new Date(Number(ano), Number(mes) - 1, Number(dia), 0, 0, 0);
    }

    return dataSaida;
  }

  //Obtém a Data UTC com hora zerada
  static getDateddMMyyy(date: Date): string {
    //codigo original
    //return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
    let dataSaida: Date;

    try {
      dataSaida = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0
      );
    } catch (error) {
      //Quando date chega neste formato: 2022-06-20T00:00:00
      //tento pegar ano/mes/dia direto
      //Date(DtIniFer.getUTCFullYear(), DtIniFer.getUTCMonth(), DtIniFer.getUTCDate(), 0 , 0 , 0);
      //e não funciona
      const dia: string = date.toString().substring(8, 10);
      const mes: string = date.toString().substring(5, 7);
      const ano: string = date.toString().substring(0, 4);

      dataSaida = new Date(Number(ano), Number(mes) - 1, Number(dia), 0, 0, 0);
    }

    function pad(s: any) {
      return s < 10 ? '0' + s : s;
    }

    return [
      pad(dataSaida.getUTCDate()),
      pad(dataSaida.getUTCMonth() + 1),
      pad(dataSaida.getUTCFullYear()),
    ].join('/');
  }

  //Obtém a Data UTC com a hora
  static getDateddMMyyyyHhmmss(date: Date): string {
    let dataSaida: Date;

    try {
      dataSaida = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      );
    } catch (error) {
      //Quando date chega neste formato: 2022-06-20T00:00:00
      //tento pegar ano/mes/dia direto
      //Date(DtIniFer.getUTCFullYear(), DtIniFer.getUTCMonth(), DtIniFer.getUTCDate(), 0 , 0 , 0);
      //e não funciona
      const dia: string = date.toString().substring(8, 10);
      const mes: string = date.toString().substring(5, 7);
      const ano: string = date.toString().substring(0, 4);
      const hora: string = date.toString().substring(11, 13);
      const minutos: string = date.toString().substring(14, 16);
      const segundos: string = date.toString().substring(17, 19);

      dataSaida = new Date(
        Number(ano),
        Number(mes) - 1,
        Number(dia),
        Number(hora),
        Number(minutos),
        Number(segundos)
      );
    }

    function pad(s: any) {
      return s < 10 ? '0' + s : s;
    }

    let dataFormatada: string = [
      pad(dataSaida.getDate()),
      pad(dataSaida.getUTCMonth() + 1),
      pad(dataSaida.getUTCFullYear()),
    ].join('/');

    dataFormatada += ' ';
    dataFormatada += [
      pad(dataSaida.getHours()),
      pad(dataSaida.getMinutes()),
      pad(dataSaida.getSeconds()),
    ].join(':');

    return dataFormatada;
  }

  //Obtém a Data/hora UTC
  static getDateTimeUTC(date: Date): Date {
    let dataSaida: Date;

    try {
      dataSaida = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      );
    } catch (error) {
      //Quando date chega neste formato: 2022-06-20T11:12:13
      const dia: string = date.toString().substring(8, 10);
      const mes: string = date.toString().substring(5, 7);
      const ano: string = date.toString().substring(0, 4);
      const hora: string = date.toString().substring(11, 13);
      const min: string = date.toString().substring(14, 16);
      const seg: string = date.toString().substring(17, 19);
      dataSaida = new Date(
        Number(ano),
        Number(mes) - 1,
        Number(dia),
        Number(hora),
        Number(min),
        Number(seg)
      );
    }

    return dataSaida;
  }

  static compareEqualDates(date1: Date, date2: Date): boolean {
    let strDate1: string = Utils.getDateddMMyyy(date1);
    let strDate2: string = Utils.getDateddMMyyy(date2);

    if (strDate1 === strDate2) {
      return true;
    } else {
      return false;
    }
  }

  static convertMMYYYYToDate(value: string): Date {
    value = value.replace('/', '');

    let month: number = Number(value.substring(0, 2));
    let year: number = Number(value.substring(2, 6));

    //Diminui 1 pois no angular existe um índice do mês que não é igual ao mês.
    month = month - 1;

    return new Date(year, month, 1);
  }

  static getNullDate(): Date {
    return new Date(1900, 0, 1, 0, 0, 0);
  }

  static getDateWebServiceFormat(value: Date): string {
    if (value.toString() == '') {
      return '1900-01-01';
    }

    let data = Utils.getDateddMMyyy(value);

    return (
      data.substring(6, 10) +
      '-' +
      data.substring(3, 5) +
      '-' +
      data.substring(0, 2)
    );
  }

  static getDateTimeWebServiceFormat(value: Date): string {
    if (
      value.toString() == '' ||
      Utils.getDateddMMyyy(value) == Utils.getDateddMMyyy(this.getNullDate())
    ) {
      return '1900-01-01T00:00:00';
    }

    let data = this.getDateddMMyyyyHhmmss(value);

    let dataFormatada: string =
      data.substring(6, 10) +
      '-' +
      data.substring(3, 5) +
      '-' +
      data.substring(0, 2);
    dataFormatada = dataFormatada + 'T';
    dataFormatada =
      dataFormatada +
      data.substring(11, 13) +
      ':' +
      data.substring(14, 16) +
      ':' +
      data.substring(17, 19);

    return dataFormatada;
  }

  static PrimeiroDiaMes(data: Date): Date {
    let dataSaida: Date = this.getDateTimeUTC(data);
    //Posiciona no dia 01 do mes
    dataSaida = new Date(
      dataSaida.getUTCFullYear(),
      dataSaida.getUTCMonth(),
      1,
      0,
      0,
      0
    );
    return dataSaida;
  }

  static UltimoDiaMes(data: Date): Date {
    let dataSaida: Date = this.getDateTimeUTC(data);
    // adiciona 1 mes
    dataSaida.setMonth(dataSaida.getUTCMonth() + 1);
    //Posiciona no dia 01 do mes adicionado
    dataSaida = new Date(
      dataSaida.getUTCFullYear(),
      dataSaida.getUTCMonth(),
      1,
      0,
      0,
      0
    );
    // subtrai 1 dia para ir para o ultimo dia do mes
    dataSaida.setDate(dataSaida.getUTCDate() - 1);

    return dataSaida;
  }

  static converterValorMoeda(valor: number): string {
    //const valorMoeda = parseFloat(valor);
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  //Transforma a string Base64 num array de bytes e faz o download do arquivo.
  static downloadFileBase64(fleName: string, file: string): void {
    const byteString = window.atob(file);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([int8Array]);

    const url = window.URL.createObjectURL(blob);

    //Abio
    //O window.open foi comentado porque no download dos arquivos digitais abria uma janela com o blob do arquivo.
    //Comentar não afetou o download do contra-cheque.
    //window.open(url);
    var a = document.createElement('a');

    a.href = url;
    a.target = '_blank';
    a.download = fleName;

    document.body.appendChild(a);

    a.click();
  }

  /**
   * Abre o popup do navegador para baixar um arquivo que veio de um blob.
   *
   * @param blob Conteúdo do arquivo binário a ser baixado.
   * @param fileName Nome do arquivo a ser salvo.
   */
  static downloadFile(blob: Blob, fileName: string): void {
    const anchor = window.document.createElement('a');

    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;

    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  static base64toFile(base64Data: string): File {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new File([new Blob(byteArrays)], 'teste', { type: 'image/jpeg' });
  }

  static b64DecodeUnicode(str: string): string {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  //Largura a partir de onde remonta o ngx_datatable para mobile
  static getIsMobile() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return width < 768;
  }
}
