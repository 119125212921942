<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-12">
          <div class="card-title-form">Plano de Contas
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar"
              placement="top" [adaptivePosition]="false">
              <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
                <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
            </span>
          </div>

          <div style="margin-top: 0.5%; margin-bottom: 0.5%;" class="row w-100">
            <span class="col-lg-8" style="align-content: end;">
              <input type="text" #search id="search" formControlName="search" name="search"
                class="form-control form-control-lg"
                placeholder="Pesquisar por uma das colunas abaixo na listagem abaixo..."
                (keyup)="updateFilter($event)" />
            </span>
            <span class="col-lg-2">
              <label class="form-label">Inativos? </label>
              <span class="d-block"><ui-switch name="mostrarInativos" id="mostrarInativos"
                  formControlName="mostrarInativos" (click)="toggleMostrarInativos();"></ui-switch></span>
            </span>
            <span class="col-lg-2" style="align-content: end;">
              <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass"
                tooltip="Exporta para o Excel os dados consultados abaixo." [adaptivePosition]="false"
                (click)="generateExcell();">
                <i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar Excel
              </button>
            </span>
          </div>

          <div class="card-body-form">
            <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="planoConta"
              [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
              [rowHeight]="!isMobile ? 40 : 150" [scrollbarH]="false" [scrollbarV]="true" style="height: 430px;" [limit]="!isMobile ? 7 : 4" [messages]="my_messages"
              [sorts]="[{prop: 'Ordenacao', dir: 'asc'}]">

              <ngx-datatable-column prop="CodTextoPlanoConta" [draggable]="false" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Código</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Código</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.CodTextoPlanoConta}}" placement="auto"
                    [adaptivePosition]="false">{{row.CodTextoPlanoConta }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="DescricaoPlanoConta" [draggable]="false" [flexGrow]="3">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.DescricaoPlanoConta}}" placement="auto"
                    [adaptivePosition]="false">{{row.DescricaoPlanoConta }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="TipoPlanoContaDescr" [draggable]="false" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Tipo</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Tipo</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.TipoPlanoContaDescr}}" placement="auto"
                    [adaptivePosition]="false">{{row.TipoPlanoContaDescr }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="ContaPai" [draggable]="false" [flexGrow]="3">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Conta Pai</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Conta Pai</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.ContaPai}}" placement="auto"
                    [adaptivePosition]="false">{{row.ContaPai }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="ColunaRelatorioDescr" [draggable]="false" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Relatório</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Relatório</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.ColunaRelatorioDescr}}" placement="auto"
                    [adaptivePosition]="false">{{row.ColunaRelatorioDescr }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="1" [sortable]="false"
                [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Ações</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Ações</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top right"
                    [adaptivePosition]="false" (click)="Edicao(row);">
                    <i class="fa-solid fa-pen-to-square"></i></span>
                  <span class="iconeTamanho" containerClass="customClass" tooltip="Exclusão" placement="top"
                    style="padding-left: 6% !important;padding-right: 6% !important;" [adaptivePosition]="false"
                    (click)="openModalExcluir(confirmaExclusao, row.CodigoPlanoConta, row.NomePlanoConta);">
                    <i class="fa-solid fa-trash"></i></span>
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<ng-template #confirmaExclusao>
  <div>
    <div class="modal-header modal-header-exclusao">
      <h5 class="modal-title">Confirmação de Exclusão</h5>
    </div>
    <div class="modal-body">
      <p>Confirma a exclusão da conta '{{nomePlanoConta}}'?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ExcluiPlanoContaByCodigo()">Sim</button>
      <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeModal()">Não</button>
    </div>
  </div>
</ng-template>
