<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-10">

          <div class="card-title-form">Saldo do Dia
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">

              <div class="row">
                <div class="col-lg-3">
                  <label class="form-label" style="width: 100%;">Data Saldo</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event);"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                    name="formDataSaldo" id="formDataSaldo" formControlName="formDataSaldo"
                    [value]="form.get('formDataSaldo')">
                </div>
                <!-- <div class="col-lg-3">
                  <label class="form-label" style="width: 100%;">&nbsp;&nbsp;&nbsp;</label>
                  <button type="button" class="btn btn-primary top1" (click)="GetDSaldoCalculadoProxData(false);">Buscar
                    Movimentação</button>
                </div> -->
                <div class="col-lg-3">
                  <label class="form-label" for="formValorContaCorrente">Conta Corrente</label>
                  <input type="text" id="formValorContaCorrente" formControlName="formValorContaCorrente"
                    [value]="form.get('formValorContaCorrente')" name="formValorContaCorrente"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="formValorFundoFixo">Fundo Fixo</label>
                  <input type="text" id="formValorFundoFixo" formControlName="formValorFundoFixo"
                    [value]="form.get('formValorFundoFixo')" name="formValorFundoFixo"
                    class="form-control form-control-lg" currencyMask />
                </div>
              </div>

              <div class="row top02">
                <div class="col-lg-3">
                  <label class="form-label" for="formValorInicialAplicacao">Valor Inicial Poupança</label>
                  <input type="text" id="formValorInicialAplicacao" formControlName="formValorInicialAplicacao"
                    [value]="form.get('formValorInicialAplicacao')" name="formValorInicialAplicacao"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="formDepositoPoupanca">Depósito Poupança</label>
                  <input type="text" id="formDepositoPoupanca" formControlName="formDepositoPoupanca"
                    [value]="form.get('formDepositoPoupanca')" name="formDepositoPoupanca"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="formRetiradaPoupanca">Retirada Poupança</label>
                  <input type="text" id="formRetiradaPoupanca" formControlName="formRetiradaPoupanca"
                    [value]="form.get('formRetiradaPoupanca')" name="formRetiradaPoupanca"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="formValorAplicacao">Valor Final Poupança</label>
                  <input type="text" id="formValorAplicacao" formControlName="formValorAplicacao"
                    [value]="form.get('formValorAplicacao')" name="formValorAplicacao"
                    class="form-control form-control-lg" currencyMask />
                </div>
              </div>

              <div class="row top02">
                <div class="col-lg-3">
                  <label class="form-label" for="formValorInicialCDB">Valor Inicial CDB</label>
                  <input type="text" id="formValorInicialCDB" formControlName="formValorInicialCDB"
                    [value]="form.get('formValorInicialCDB')" name="formValorInicialCDB"
                    class="form-control form-control-lg" currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="formDepositoCDB">Depósito CDB</label>
                  <input type="text" id="formDepositoCDB" formControlName="formDepositoCDB"
                    [value]="form.get('formDepositoCDB')" name="formDepositoCDB" class="form-control form-control-lg"
                    currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="formRetiradaCDB">Retirada CDB</label>
                  <input type="text" id="formRetiradaCDB" formControlName="formRetiradaCDB"
                    [value]="form.get('formRetiradaCDB')" name="formRetiradaCDB" class="form-control form-control-lg"
                    currencyMask />
                </div>
                <div class="col-lg-3">
                  <label class="form-label" for="formValorCDB">Valor Final CDB</label>
                  <input type="text" id="formValorCDB" formControlName="formValorCDB" [value]="form.get('formValorCDB')"
                    name="formValorCDB" class="form-control form-control-lg" currencyMask />
                </div>
              </div>

              <div class="top2">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button type="button" class="btn btn-primary" (click)="SalvaSaldo(true);" [disabled]="this.saldo.CodigoSaldo != -1">Salvar e Novo</button>
                  <button type="button" class="btn btn-primary" (click)="SalvaSaldo(false);">Salvar</button>
                  <button type="button" class="btn btn-primary" (click)="novo();">Novo</button>
                </div>
              </div>

              <div style="border-top:groove;margin-top:7px;border-top-color: #FBB035;">

                <div class="row top1">
                  <div class="col-lg-3">
                    <label class="form-label" for="formDataAnterior" style="width: 100%;">Data Anterior</label>
                    <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                      [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                      name="formDataAnterior" id="formDataAnterior" formControlName="formDataAnterior"
                      [value]="form.get('formDataAnterior')">
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label" for="formSaldoAnterior">Saldo Anterior</label>
                    <input type="text" id="formSaldoAnterior" formControlName="formSaldoAnterior"
                      [value]="form.get('formSaldoAnterior')" name="formSaldoAnterior"
                      class="form-control form-control-lg" currencyMask />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label" for="formDataAtual" style="width: 100%;">Data Atual</label>
                    <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                      [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                      name="formDataAtual" id="formDataAtual" formControlName="formDataAtual"
                      [value]="form.get('formDataAtual')">
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label" for="formValorCalculo">Cálculo</label>
                    <input type="text" id="formValorCalculo" formControlName="formValorCalculo"
                      [value]="form.get('formValorCalculo')" name="formValorCalculo"
                      class="form-control form-control-lg" currencyMask />
                  </div>
                </div>

                <div class="row top1">
                  <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="dataSaldoCalc.Contas"
                    [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50"
                    [rowHeight]="!isMobile ? 40 : 250" [scrollbarH]="false" [scrollbarV]="true" [limit]="7"
                    [messages]="my_messages" style="height: 360px;" [sorts]="[{prop: 'DataSaldo', dir: 'desc'}]">

                    <ngx-datatable-column prop="Tipo" [draggable]="false" [flexGrow]="4">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Tipo</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Tipo</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span containerClass="customClass" tooltip="{{row.Tipo}}" placement="auto"
                          [adaptivePosition]="false">{{ row.Tipo }}</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column prop="Descricao" [draggable]="false" [flexGrow]="4">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span containerClass="customClass" tooltip="{{row.Descricao}}" placement="auto"
                          [adaptivePosition]="false">{{row.Descricao}}</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column prop="Valor" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
                      [headerClass]="'ngx-direita'">
                      <ng-template ngx-datatable-header-template>
                        <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Valor</span>
                        <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Valor</span>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span containerClass="customClass" tooltip="{{row.Valor | currency: 'BRL'}}" placement="auto"
                          [adaptivePosition]="false" style="padding-right: 9% !important;">{{row.Valor | currency:
                          'BRL'}}</span>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>
                </div>

              </div>

            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<div class="offcanvas offcanvas-top h-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">

    <div class="row d-flex align-items-center justify-content-center h-100">
      <div class="col-lg-10">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Edição de Categoria do Associado
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">

          <div class="row">
            <div class="col-lg-3">
              <label class="form-label" style="width: 100%;">Data Saldo</label>
              <input type="text" disabled class="form-control form-control-lg"
              placeholder="Data a qual este saldo se refere." />
            </div>
            <!-- <div class="col-lg-3">
              <label class="form-label" style="width: 100%;">&nbsp;&nbsp;&nbsp;</label>
              <button type="button" class="btn btn-primary top1" (click)="GetDSaldoCalculadoProxData(false);">Buscar
                Movimentação</button>
            </div> -->
            <div class="col-lg-3">
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor depositado em conta corrente." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="formValorFundoFixo">Fundo Fixo</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor guardado em espécie no fundo fixo." />
            </div>
          </div>

          <div class="row top02">
            <div class="col-lg-3">
              <label class="form-label" for="formValorInicialAplicacao">Valor Inicial Poupança</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor inical da aplicação em poupança." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="formDepositoPoupanca">Depósito Poupança</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor depositado em poupança." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="formRetiradaPoupanca">Retirada Poupança</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor retirado da poupança." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="formValorAplicacao">Valor Final Poupança</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor final da poupança incluindo juros do dia." />
            </div>
          </div>

          <div class="row top02">
            <div class="col-lg-3">
              <label class="form-label" for="formValorInicialCDB">Valor Inicial CDB</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor inical da aplicação em CDB." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="formDepositoCDB">Depósito CDB</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor depositado em CDB." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="formRetiradaCDB">Retirada CDB</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor retirado do CDB." />
            </div>
            <div class="col-lg-3">
              <label class="form-label" for="formValorCDB">Valor Final CDB</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor final do CDB incluindo juros do dia." />
            </div>
          </div>

            <div class="w-100 top1"><button type="button" class="btn btn-warning">Cancelar</button><label
                style="margin-left: 4px; margin-right: 1%;">Retorna para tela que chamou.</label>
               <button type="button" class="btn btn-primary">Salvar e Novo</button><label
              style="margin-left: 4px; margin-right: 1%;">Salva os dados editados/incluídos e busca a próxima data.</label></div>
            <div class="w-100 top1"><button type="button" class="btn btn-primary">Salvar</button><label
                style="margin-left: 4px; margin-right: 1%;">Salva os dados editados/incluídos.</label>
                <button type="button" class="btn btn-primary">Novo</button><label
              style="margin-left: 4px; margin-right: 1%;">Busca a próxima data para incluir o saldo</label></div>
          </div>
      </div>
    </div>

</div>
