<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-10">

          <div class="card-title-form">Orçamento
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">

              <div class="row">
                <!-- Plano de Contas -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPlanoContas">Plano de Contas</label>
                  <app-PlanoConta-pesquisa name="codigoPlanoConta" id="codigoPlanoConta"
                    formControlName="codigoPlanoConta" (PlanoContaOut)="changePlanoConta($event);"
                    [CodigoPlanoContaPreench]="this.codigoPlanoConta">
                  </app-PlanoConta-pesquisa>
                </div>
                <!-- Complemento -->
                <div class="col-lg-6">
                  <label class="form-label" for="formComplemento">Complemento</label>
                  <input type="text" id="formComplemento" formControlName="formComplemento" maxlength="50"
                    [value]="form.get('formComplemento')" name="formComplemento" class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formComplemento')"
                    label="descrição complementar"></app-field-error-message>
                </div>
              </div>

              <div class="row top02">
                <!-- Data do Orçamento -->
                <div class="col-lg-6">
                  <label class="form-label" style="width: 100%;">Data Orçamento</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event);"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                    name="formDataCompra" id="formDataCompra" formControlName="formDataCompra"
                    [value]="form.get('formDataCompra')">
                </div>
                <!-- Situação -->
                <div class="col-lg-6">
                  <label class="form-label" for="formSituacao">Situação</label>
                  <ngx-select-dropdown name="formSituacao" id="formSituacao" formControlName="formSituacao"
                    (change)="changeSituacao($event)" [config]="configSituacao" [options]="Situacao"
                    [(ngModel)]="orcamento.SituacaoCompraDescr" class="form_control_ngx_select_dropdown">
                  </ngx-select-dropdown>
                </div>
              </div>

              <!------- Empresa 1 -------->
              <div class="border-box"> </div>

              <div class="row top02">
                <div class="col-lg-6">
                  <label class="form-label" for="formEmpresa1">Empresa 1</label>
                  <app-fornecedor-pesquisa id="formEmpresa1" formControlName="formEmpresa1" name="formEmpresa1"
                    [CodigoSocioPreench]="this.orcamento.CodigoFornOrcam1" (AssociadoOut)="changeFornec1($event);">
                  </app-fornecedor-pesquisa>
                </div>
                <!-- Pessoa 1 -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPessoa1">Pessoa 1</label>
                  <input type="text" id="formPessoa1" formControlName="formPessoa1" name="formPessoa1"
                    class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formPessoa1')"
                    label="Pessoa 1"></app-field-error-message>
                </div>
              </div>

              <div class="row top02">
                <!-- Valor 1 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formValor1">Valor 1</label>
                  <input type="text" id="formValor1" formControlName="formValor1" name="formValor1"
                    class="form-control form-control-lg" currencyMask />
                  <app-field-error-message [control]="form.get('formValor1')" label="Valor 1"></app-field-error-message>
                </div>

                <!-- Condição 1 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formCondicao1">Condições 1</label>
                  <input type="text" id="formCondicao1" formControlName="formCondicao1" name="formCondicao1"
                    class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formCondicao1')"
                    label="Condição 1"></app-field-error-message>
                </div>

                <!-- Fone 1 -->
                <div class="col-lg-4">
                  <label class="form-label">Celular/Fone 1:</label>
                  <input type="text" class="form-control form-control-lg" mask="(00) 00000-0000" name="formNumero1"
                    id="formNumero1" formControlName="formNumero1" [value]="form.get('OrcamentoFone1')" maxlength="15">
                </div>
              </div>

              <div class="row top02">
                <!-- Observação Empresa 1 -->
                <div class="col-lg-12">
                  <label class="form-label" for="formObservacao1">Observações Empresa 1</label>
                  <textarea type="text" id="formObservacao1" formControlName="formObservacao1" rows="2"
                    [value]="form.get('OrcamentoObs1')" name="formObservacao1"
                    class="form-control form-control-lg"></textarea>
                  <app-field-error-message [control]="form.get('formObservacao1')"
                    label="descrição complementar"></app-field-error-message>
                </div>
              </div>

              <!------- Empresa 2 -------->
              <div class="border-box"> </div>

              <div class="row top02">
                <div class="col-lg-6">
                  <label class="form-label" for="formEmpresa2">Empresa 2</label>
                  <app-fornecedor-pesquisa id="formEmpresa2" formControlName="formEmpresa2" name="formEmpresa2"
                    [CodigoSocioPreench]="this.orcamento.CodigoFornOrcam2" (AssociadoOut)="changeFornec2($event);">
                  </app-fornecedor-pesquisa>
                </div>
                <!-- Pessoa 2 -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPessoa2">Pessoa 2</label>
                  <input type="text" id="formPessoa2" formControlName="formPessoa2" name="formPessoa2"
                    class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formPessoa2')"
                    label="Pessoa 2"></app-field-error-message>
                </div>
              </div>

              <div class="row top02">
                <!-- Valor 2 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formValor2">Valor 2</label>
                  <input type="text" id="formValor2" formControlName="formValor2" name="formValor2"
                    class="form-control form-control-lg" currencyMask />
                  <app-field-error-message [control]="form.get('formValor2')" label="Valor 2"></app-field-error-message>
                </div>

                <!-- Condição 2 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formCondicao2">Condições 2</label>
                  <input type="text" id="formCondicao2" formControlName="formCondicao2" name="formCondicao2"
                    class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formCondicao2')"
                    label="Condição 2"></app-field-error-message>
                </div>

                <!-- Fone 2 -->
                <div class="col-lg-4">
                  <label class="form-label">Celular/Fone 2:</label>
                  <input type="text" class="form-control form-control-lg" mask="(00) 00000-0000" name="formNumero2"
                    id="formNumero2" formControlName="formNumero2" [value]="form.get('OrcamentoFone2')" maxlength="25">
                </div>
              </div>

              <div class="row top02">
                <!-- Observação Empresa 2 -->
                <div class="col-lg-12">
                  <label class="form-label" for="formObservacao2">Observações Empresa 2</label>
                  <textarea type="text" id="formObservacao2" formControlName="formObservacao2" rows="2"
                    [value]="form.get('OrcamentoObs2')" name="formObservacao2"
                    class="form-control form-control-lg"></textarea>
                  <app-field-error-message [control]="form.get('formObservacao2')"
                    label="descrição complementar"></app-field-error-message>
                </div>
              </div>

              <!------- Empresa 3 -------->
              <div class="border-box"> </div>

              <div class="row top02">
                <div class="col-lg-6">
                  <label class="form-label" for="formEmpresa3">Empresa 3</label>
                  <app-fornecedor-pesquisa id="formEmpresa3" formControlName="formEmpresa3" name="formEmpresa3"
                    [CodigoSocioPreench]="this.orcamento.CodigoFornOrcam3" (AssociadoOut)="changeFornec3($event);">
                  </app-fornecedor-pesquisa>
                </div>
                <!-- Pessoa 3 -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPessoa3">Pessoa 3</label>
                  <input type="text" id="formPessoa3" formControlName="formPessoa3" name="formPessoa3"
                    class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formPessoa3')"
                    label="Pessoa 3"></app-field-error-message>
                </div>
              </div>

              <div class="row top02">
                <!-- Valor 3 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formValor3">Valor 3</label>
                  <input type="text" id="formValor3" formControlName="formValor3" name="formValor3"
                    class="form-control form-control-lg" currencyMask />
                  <app-field-error-message [control]="form.get('formValor3')" label="Valor 3"></app-field-error-message>
                </div>

                <!-- Condição 3 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formCondicao3">Condições 3</label>
                  <input type="text" id="formCondicao3" formControlName="formCondicao3" name="formCondicao3"
                    class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formCondicao3')"
                    label="Condição 3"></app-field-error-message>
                </div>

                <!-- Fone 3 -->
                <div class="col-lg-4">
                  <label class="form-label">Celular/Fone 3:</label>
                  <input type="text" class="form-control form-control-lg" mask="(00) 00000-0000" name="formNumero3"
                    id="formNumero3" formControlName="formNumero3" [value]="form.get('OrcamentoFone3')" maxlength="35">
                </div>
              </div>

              <div class="row top02">
                <!-- Observação Empresa 3 -->
                <div class="col-lg-12">
                  <label class="form-label" for="formObservacao3">Observações Empresa 3</label>
                  <textarea type="text" id="formObservacao3" formControlName="formObservacao3" rows="2"
                    [value]="form.get('OrcamentoObs3')" name="formObservacao3"
                    class="form-control form-control-lg"></textarea>
                  <app-field-error-message [control]="form.get('formObservacao3')"
                    label="descrição complementar"></app-field-error-message>
                </div>
              </div>

              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button type="button" class="btn btn-primary" (click)="SalvaOrcamento();">Salvar</button>
                </div>
              </div>

            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<div class="offcanvas offcanvas-top h-100" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="content-wrapper">
    <div class="container-fluid">
      <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
          <div class="col-lg-10">
            <div class="offcanvas-header">
              <div class="card-title-form-menor">Orçamento
                <button type="button" class="float-end btn-close text-primary customSearchButtons"
                  data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
                  placement="bottom" [adaptivePosition]="false"></button>
              </div>
            </div>
            <div class="offcanvas-body">

              <div class="row">
                <!-- Plano de Contas -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPlanoContas">Plano de Contas</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Conta a qual o orçamento está vinculado." />
                </div>
                <!-- Complemento -->
                <div class="col-lg-6">
                  <label class="form-label" for="formComplemento">Complemento</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Descrição complementar da necessidade (até 50 caracteres)." />
                </div>
              </div>

              <div class="row top02">
                <!-- Data do Orçamento -->
                <div class="col-lg-6">
                  <label class="form-label" style="width: 100%;">Data Orçamento</label>
                  <input disabled class="form-control form-control-lg" placeholder="Data do orçamento." />
                </div>
                <!-- Situação -->
                <div class="col-lg-6">
                  <label class="form-label" for="formSituacao">Situação</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Situação do orçamento: Aberto/Encerrado." />
                </div>
              </div>

              <!------- Empresa 1 -------->
              <div class="border-box"> </div>

              <div class="row top02">
                <div class="col-lg-6">
                  <label class="form-label" for="formEmpresa1">Empresa 1</label>
                  <input disabled class="form-control form-control-lg" placeholder="Empresa 1 escolhida para orçar." />
                </div>
                <!-- Pessoa 1 -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPessoa1">Pessoa 1</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Pessoa para contato na Empresa 1." />
                </div>
              </div>

              <div class="row top02">
                <!-- Valor 1 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formValor1">Valor 1</label>
                  <input disabled class="form-control form-control-lg" placeholder="Valor cotado na Empresa 1." />
                </div>

                <!-- Condição 1 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formCondicao1">Condições 1</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Condições de pagamento na Empresa 1." />
                </div>

                <!-- Fone 1 -->
                <div class="col-lg-4">
                  <label class="form-label">Celular/Fone 1:</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Telefone para contato na Empresa 1." />
                </div>
              </div>

              <div class="row top02">
                <!-- Observação Empresa 1 -->
                <div class="col-lg-12">
                  <label class="form-label" for="formObservacao1">Observações Empresa 1</label>
                  <textarea type="text" disabled class="form-control form-control-lg" rows="2"
                    placeholder="Informações que possam ser relevantes sobre a empresa 1."></textarea>
                </div>
              </div>

              <!------- Empresa 2 -------->
              <div class="border-box"> </div>

              <div class="row top02">
                <div class="col-lg-6">
                  <label class="form-label" for="formEmpresa2">Empresa 2</label>
                  <input disabled class="form-control form-control-lg" placeholder="Empresa 2 escolhida para orçar." />
                </div>
                <!-- Pessoa 2 -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPessoa2">Pessoa 2</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Pessoa para contato na Empresa 2." />
                </div>
              </div>

              <div class="row top02">
                <!-- Valor 2 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formValor2">Valor 2</label>
                  <input disabled class="form-control form-control-lg" placeholder="Valor cotado na Empresa 2." />
                </div>

                <!-- Condição 2 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formCondicao2">Condições 2</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Condições de pagamento na Empresa 2." />
                </div>

                <!-- Fone 2 -->
                <div class="col-lg-4">
                  <label class="form-label">Celular/Fone 2:</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Telefone para contato na Empresa 2." />
                </div>
              </div>

              <div class="row top02">
                <!-- Observação Empresa 2 -->
                <div class="col-lg-12">
                  <label class="form-label" for="formObservacao2">Observações Empresa 2</label>
                  <textarea type="text" disabled class="form-control form-control-lg" rows="2"
                    placeholder="Informações que possam ser relevantes sobre a empresa 2."></textarea>
                </div>
              </div>

              <!------- Empresa 3 -------->
              <div class="border-box"> </div>

              <div class="row top02">
                <div class="col-lg-6">
                  <label class="form-label" for="formEmpresa3">Empresa 3</label>
                  <input disabled class="form-control form-control-lg" placeholder="Empresa 3 escolhida para orçar." />
                </div>
                <!-- Pessoa 3 -->
                <div class="col-lg-6">
                  <label class="form-label" for="formPessoa3">Pessoa 3</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Pessoa para contato na Empresa 3." />
                </div>
              </div>

              <div class="row top02">
                <!-- Valor 3 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formValor3">Valor 3</label>
                  <input disabled class="form-control form-control-lg" placeholder="Valor cotado na Empresa 3." />
                </div>

                <!-- Condição 3 -->
                <div class="col-lg-4">
                  <label class="form-label" for="formCondicao3">Condições 3</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Condições de pagamento na Empresa 3." />
                </div>

                <!-- Fone 3 -->
                <div class="col-lg-4">
                  <label class="form-label">Celular/Fone 3:</label>
                  <input disabled class="form-control form-control-lg"
                    placeholder="Telefone para contato na Empresa 3." />
                </div>
              </div>

              <div class="row top02">
                <!-- Observação Empresa 3 -->
                <div class="col-lg-32">
                  <label class="form-label" for="formObservacao3">Observações Empresa 3</label>
                  <textarea type="text" disabled class="form-control form-control-lg" rows="2"
                    placeholder="Informações que possam ser relevantes sobre a empresa 3."></textarea>
                </div>
              </div>

              <div class="w-100 top02"><button type="button" class="btn btn-warning">Cancelar</button><label
                  style="margin-left: 4px; margin-right: 1%;">Retorna para tela de lista de orçamentos.</label></div>
              <div class="w-100 top02"><button type="button" class="btn btn-primary">Salvar</button><label
                  style="margin-left: 4px; margin-right: 1%;">Salva o orçamento selecionado.</label></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
