import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { MessageService } from '../../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';
import { SocioResumo } from '../../../associados/models/associados';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Profissional } from '../models/profissional';
import { ExcelService } from 'src/app/excel.service';
import ProfissionalService from '../profissional-service';
import { DataTableFooterComponent } from '@swimlane/ngx-datatable';
import { AssociadosService } from '../../../associados/associados.service';

@Component({
  selector: 'app-profissional-lista',
  templateUrl: './profissional-lista.component.html',
  styleUrls: ['./profissional-lista.component.css'],
})

export class ProfissionalListaComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }
  constructor(
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private ProfissionalService: ProfissionalService,
    private utilsservice: UtilsService,
    private associadosService: AssociadosService,
  ) {}
  
  @Input() origemSubTela: number = 0;
  @Input() origemSubTelaCodigo: number = 0;
  profissional: Profissional[] = [];
  profissionalOriginal: Profissional[] = [];
  novaProfissional: Profissional = new Profissional();
  listaAssociadoOriginal: SocioResumo[] = [];
  form: FormGroup;
  indexPagina: number = 1;
  totalLinhas: number = 0;
  @ViewChild('tFooter', { static: false }) tFooter: DataTableFooterComponent;
  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.'
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.spinner.show();
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaProfissional();
    this.BuscaAssociado();
    this.criarForm(this.novaProfissional);
    this.spinner.hide();
            
  }

  preencherComValoresAleatorios(): void {
    const randomInscricoes: Profissional[] = [];
    for (let i = 0; i < 5; i++) { // Preenche 5 orçamentos fictícios
      const inscricaoAleatoria = new Profissional();
      inscricaoAleatoria.Codigo = 8733;
      inscricaoAleatoria.Nome = `Nome${i + 1}`;
      inscricaoAleatoria.Funcao = `coordenacao`;
      inscricaoAleatoria.Mesa = `Mesa${i + 1}`;
      inscricaoAleatoria.Parecer = `Parecer${i + 1}`;
      randomInscricoes.push(inscricaoAleatoria);
    }
    
    this.profissionalOriginal = randomInscricoes;
    this.profissional = this.profissionalOriginal;
  }

  BuscaAssociado(): void {
    try {
      this.associadosService
        .ListaTodosSocios()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.SociosResumo.forEach((item: any) => {
                    const tempList = new SocioResumo();
                    tempList.carregar(item);
                    this.listaAssociadoOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  BuscaProfissional(): void {
    this.spinner.show();
    this.profissionalOriginal = [];
    try {
      this.ProfissionalService
        .GetListaGeral(this.indexPagina, 0, this.origemSubTelaCodigo)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                
                response.ProfissionalLista.forEach((item: any) => {
                    const tempList = new Profissional();
                    tempList.carregar(item);
                    this.profissionalOriginal.push(tempList);
                  });
            }
            this.profissional = this.profissionalOriginal;

            this.my_messages.totalMessage = ` linhas encontradas.`
          },
          error: (error) => {
            this.spinner.hide();
            //this.utilsservice.showHttpError(error);
            console.log("retirar o comentario do alerta")
            this.preencherComValoresAleatorios();
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
      
    }
  }
  
  Edicao(profissional: Profissional) {
    setTimeout(() => {
        this.criarForm(profissional); // Inicializa o formulário com os dados do profissional
        // lógica para abrir o modal
      });
   }

  Adicionar() {
    this.novaProfissional = new Profissional();
    this.novaProfissional.Nome = '';
    this.novaProfissional.Codigo = -1;
    this.novaProfissional.Funcao = "coordenacao";
    setTimeout(() => {
        this.criarForm(this.novaProfissional); // Inicializa o formulário com os dados do profissional
        // lógica para abrir o modal
      });
   } 

   gerarCertificado(profissional: Profissional) {
    console.log("Gerar certificado aqui")
  }

  fecharModal() {
    const modalElement = document.getElementById('modalAdicionar');
    
    if (modalElement) {
   //   const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
  
      // Fecha o modal
      //modalInstance.hide();
    }
  }

  salvarProfissional() {
    let requestProfissional: Profissional = new Profissional();
    requestProfissional.Codigo = this.form.get("formProfissionalLista")?.value;
    requestProfissional.Funcao = this.form.get("formFuncao")?.value;
    requestProfissional.Parecer = this.form.get("formParecer")?.value;

    if (!requestProfissional.Codigo) {
        this.messageService.showAlertDanger("Profissional precisa ser selecionado.");     
    }
  
    this.spinner.show();
    this.ProfissionalService.SalvaProfissional(requestProfissional)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.Error) {
            this.messageService.showAlertDanger(response.ErrorMessage);
          } else {
            this.messageService.showAlertSuccess('Profissional adicionado com sucesso!');
            this.BuscaProfissional(); // Atualiza a lista
            this.fecharModal(); // Fecha o modal
          }
        },
        error: (error) => {
          this.spinner.hide();
          this.messageService.showAlertDanger('Erro ao salvar o profissional.');
          console.error(error);
        }
      });
  }
  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    const temp = this.profissionalOriginal.filter(function (d: any) {
      return (
        d.Nome.toLowerCase().indexOf(val) !== -1 || d.Nome.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.profissional = temp;
    return true;
  }

  criarForm(dados: Profissional) {
    this.form = this.formBuilder.group({
        formProfissionalLista: [this.listaAssociadoOriginal, Validators.required],
      formFuncao: [dados.Funcao || ''],
      formParecer: [dados.Parecer || '', [Validators.maxLength(255)]],
    });
    console.log(dados)
    this.form.get('formProfissionalLista')?.setValue(dados.Codigo);
    this.form.get('formFuncao')?.setValue(dados.Funcao);
    this.form.get('formParecer')?.setValue(dados.Parecer);
  }

}
