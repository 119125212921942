<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-9">
          <!-- Solicitações -->

          <div class="card-title-form">Salas</div>
          <div class="card-body-form">
            <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="salas"
              [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
              [rowHeight]="!isMobile ? 40 : 150" [scrollbarH]="false" [scrollbarV]="true" style="height: 460px;" [limit]="8" [messages]="my_messages"
              [sorts]="[{prop: 'Ordenacao', dir: 'asc'}]">

              <ngx-datatable-column prop="CodigoSala" [draggable]="false" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Sala</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Sala</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.CodigoSala}}" placement="auto"
                    [adaptivePosition]="false">{{row.CodigoSala }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="NomeSala" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.NomeSala}}" placement="auto"
                    [adaptivePosition]="false">{{row.NomeSala}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="TaxaSala" [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'"
                [draggable]="false" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Taxa Sala</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Taxa</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.TaxaSala | currency: 'BRL'}}" placement="auto"
                    [adaptivePosition]="false">{{row.TaxaSala | currency: 'BRL'}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="SalaOnLine" [draggable]="false" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Sala OnLine</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">OnLine</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.SalaOnLine" containerClass="customClass" tooltip="Sim" placement="auto"
                    [adaptivePosition]="false">Sim</span>
                  <span *ngIf="!row.SalaOnLine" containerClass="customClass" tooltip="Não" placement="auto"
                    [adaptivePosition]="false">Não</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Ações" [sortable]="false" [cellClass]="'ngx-direita'"
                [headerClass]="'ngx-direita'" [draggable]="false" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span
                    *ngIf="row.CodigoSala == 1 || row.CodigoSala == 2 || row.CodigoSala == 3 || row.CodigoSala == 4 || row.CodigoSala == 9"
                    class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top"
                    [adaptivePosition]="false" (click)="Edicao(row.CodigoSala); ">
                    <i class="fa-solid fa-pen-to-square"></i></span>
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
