import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import PlanoContaService from '../planoconta-service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { PlanoConta } from '../models/planoconta';
import { Lista } from '../../models-gerais/lista';
import { ListasGeraisService } from '../../listasgerais/listasgerais.service';

@Component({
  selector: 'app-planoconta-edicao',
  templateUrl: './planoconta-edicao.component.html',
  styleUrls: ['./planoconta-edicao.component.css'],
})
export class PlanoContaEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  codigoPlanoConta: number = 0;
  form: FormGroup;
  nomePlanoConta: string = '';
  codTextoPlanoConta: string = '';
  tipoPlanoConta: string = '';
  tipoPlanoContaDescr: string = '';
  somarRelatorio: boolean = false;
  codigoPlanoContaPai: number = 0;
  ativoPlanoConta: boolean = false;
  planoConta: PlanoConta = new PlanoConta();
  colunaRelatorio: string = '';
  colunaRelatorioDescr: string = '';

  TipoPlanoConta: Lista = new Lista();
  TipoColunaRel: Lista = new Lista();
  configTipo = {
    search: true,
    placeholder: 'Selecione o tipo', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'Descricao', //TROCA CAMPO DESCRIÇÃO+
    height: '200px',
    limitTo: 0
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private planoContaService: PlanoContaService,
    private listasGeraisService: ListasGeraisService,
    private utilsservice: UtilsService
  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { planoconta } = window.history.state;
    this.planoConta = planoconta
    this.codigoPlanoConta = this.planoConta.CodigoPlanoConta;
    this.codTextoPlanoConta = this.planoConta.CodTextoPlanoConta;
    this.nomePlanoConta = this.planoConta.DescricaoPlanoConta;
    this.tipoPlanoConta =  this.planoConta.TipoPlanoConta;
    this.tipoPlanoContaDescr =  this.planoConta.TipoPlanoContaDescr;
    this.somarRelatorio =  this.planoConta.SomaRelatorio;
    this.codigoPlanoContaPai = this.planoConta.CodigoPlanoContaPai;
    this.ativoPlanoConta =  this.planoConta.AtivoPlanoConta;
    this.colunaRelatorio =  this.planoConta.ColunaRelatorio;
    this.colunaRelatorioDescr =  this.planoConta.ColunaRelatorioDescr;

    this.GetListasFixas();

  }

  GetListasFixas(): void {
    this.spinner.show();

    let ArrayListas: string[] = [];
    ArrayListas.push('TipoPlanoContaDescr');
    ArrayListas.push('TipoColunaRel');
    try {
      this.listasGeraisService
        .GetListasFixas(ArrayListas)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.TipoPlanoConta = response.ListasFixas[0];
              this.TipoColunaRel = response.ListasFixas[1];
              this.criarForm();
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  changeTipoPlanoConta($event: any) {
    this.tipoPlanoConta  = $event.value.Valor;
    this.tipoPlanoContaDescr = $event.value.Descricao;
  }

  changeColunaRel($event: any) {
    this.colunaRelatorio  = $event.value.Valor;
    this.colunaRelatorioDescr = $event.value.Descricao;
  }

  changePlanoConta($event: PlanoConta) {
    this.codigoPlanoContaPai = $event.CodigoPlanoConta;
  }

  toggleAtivo() {
    this.ativoPlanoConta = !this.ativoPlanoConta;
  }

  toggleSomarRelatorio() {
    this.somarRelatorio = !this.somarRelatorio;
  }

  SalvaPlanoConta(): void {
    let requestPlanoConta: PlanoConta = new PlanoConta();
    requestPlanoConta.CodigoPlanoConta = this.planoConta.CodigoPlanoConta;
    requestPlanoConta.CodTextoPlanoConta = this.form.get("formCodTextoPlanoConta")?.value;
    requestPlanoConta.DescricaoPlanoConta = this.form.get("formNomePlanoConta")?.value;
    requestPlanoConta.TipoPlanoConta = this.tipoPlanoConta;
    requestPlanoConta.CodigoPlanoContaPai =this.codigoPlanoContaPai;
    requestPlanoConta.SomaRelatorio = this.form.get("formSomarRelatorio")?.value;
    requestPlanoConta.AtivoPlanoConta = this.form.get("formAtivo")?.value;
    requestPlanoConta.ColunaRelatorio = this.colunaRelatorio;

    if (requestPlanoConta.CodTextoPlanoConta == '')
        this.messageService.showAlertDanger("Plano de Contas deve ser informada.");

    else if (requestPlanoConta.DescricaoPlanoConta == '')
        this.messageService.showAlertDanger("Descrição do Plano de Contas deve ser informada.");

    else if (requestPlanoConta.TipoPlanoConta == '')
        this.messageService.showAlertDanger("Tipo precisa ser informado.");

    else {
      this.spinner.show();
      try {
        this.planoContaService
          .SalvaPlanoConta(
            requestPlanoConta
          )
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  criarForm() {
    this.form = this.formBuilder.group({
        formCodTextoPlanoConta: [this.codTextoPlanoConta, [Validators.maxLength(16)]],
        formNomePlanoConta: [this.nomePlanoConta, [Validators.maxLength(60)]],
        TipoPlanoContaDescr: [this.tipoPlanoContaDescr],
        ColunaRelatorioDescr: [this.colunaRelatorioDescr],
        formSomarRelatorio: [this.somarRelatorio],
        formAtivo: [this.ativoPlanoConta],
    });

  }

  voltar() {
    this.router.navigateByUrl('/administrativo/planocontas');
  }
}
