export class Saldo {
  CodigoSaldo: number;
  DataSaldo: Date;
  ValorContaCorrente: number;
  ValorAplicacao: number;
  ValorFundoFixo: number;
  ValorInicialAplicacao: number;
  DepositoPoupanca: number;
  RetiradaPoupanca: number;
  ValorCDB: number;
  ValorInicialCDB: number;
  DepositoCDB: number;
  RetiradaCDB: number;
  constructor() {
    this.CodigoSaldo = 0;
    this.ValorAplicacao = 0;
    this.ValorContaCorrente = 0;
    this.ValorFundoFixo = 0;
    this.ValorInicialAplicacao = 0;
    this.DepositoPoupanca = 0;
    this.RetiradaPoupanca = 0;
    this.ValorCDB = 0;
    this.ValorInicialCDB = 0;
    this.DepositoCDB = 0;
    this.RetiradaCDB = 0;
  }
}

export class DataSaldoCalc {
  CodConta: number;
  DataSaldo: Date;
  ValorSaldo: number;
  ValorSaldoFF: number;
  ValorSaldoAplicacao: number;
  ValorSaldoCDB: number;
  DataSaldoAnterior: Date;
  ValorSaldoAnterior: number;
  ValorSaldoFFAnterior: number;
  ValorSaldoDia: number;
  Contas: Conta[] = [];
  constructor() {
    this.CodConta = 0;
    this.ValorSaldo = 0;
    this.ValorSaldoFF = 0;
    this.ValorSaldoAplicacao = 0;
    this.ValorSaldoCDB = 0;
    this.ValorSaldoAnterior = 0;
    this.ValorSaldoFFAnterior = 0;
    this.ValorSaldoDia = 0;
  }
}

export class Conta {
  Tipo: string;
  Descricao: string;
  Valor: number;
  constructor() {
    this.Descricao = '';
    this.Tipo = '';
    this.Valor = 0;
  }
}
