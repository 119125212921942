<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">

      <div class="row d-flex align-items-center justify-content-center h-100">

        <div class="col-lg-6">
          <div class="card-title-form">Categoria
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <div class="table-responsive table-responsive-overflow-450">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-lg-12">
                    <label class="form-label" for="formDescricaoCategoria">Descrição da Categoria</label>
                    <input type="text" id="formDescricaoCategoria" formControlName="formDescricaoCategoria"
                      [value]="form.get('formDescricaoCategoria')" name="formDescricaoCategoria"
                      class="form-control form-control-lg" maxlength="50" />
                    <app-field-error-message [control]="form.get('formDescricaoCategoria')"
                      label="descrição da categoria"></app-field-error-message>
                  </div>
                </div>
                <div class="row top1">
                  <div class="col-lg-6">
                    <label class="form-label" for="formPagaMensalidade">Paga mensalidade?</label>
                    <span class="d-block">
                      <ui-switch name="PagaMensalidade" id="PagaMensalidade" formControlName="formPagaMensalidade"
                        size="large" [checked]="form.get('formPagaMensalidade')?.value" (change)="toggleDiv()">
                      </ui-switch>
                    </span>
                  </div>
                  <div class="col-lg-6" id="formValorMensalidade">
                    <label class="form-label" for="formValorMensalidade">Valor Mensalidade</label>
                    <input type="text" id="formValorMensalidade" formControlName="formValorMensalidade"
                      [value]="form.get('formValorMensalidade')" name="formValorMensalidade"
                      class="form-control form-control-lg" currencyMask />
                    <app-field-error-message [control]="form.get('formValorMensalidade')"
                      label="Valor"></app-field-error-message>
                  </div>
                </div>
              </form>
            </div>
            <div class="top1">
              <div class="table-responsive linha-de-botoes">
                <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                <button [disabled]="form.invalid" type="button" class="btn btn-primary"
                  (click)="SalvaCategoria();">Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-top h-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center h-100">
      <div class="col-lg-6">
        <div class="offcanvas-header">
          <div class="card-title-form-menor">Categoria
            <button type="button" class="float-end btn-close text-primary customSearchButtons"
              data-bs-dismiss="offcanvas" aria-label="Fechar" containerClass="customClass" tooltip="Fechar Help"
              placement="bottom" [adaptivePosition]="false"></button>
          </div>
        </div>
        <div class="offcanvas-body">

          <div class="row">
            <div class="col-lg-6">
              <label class="form-label">Descrição da Categoria</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Necessário informar a descição da categoria (máximo 50 caracteres)." />
            </div>
          </div>

          <div class="row top1">
            <div class="col-lg-6">
              <label class="form-label" for="formPagaMensalidade">Paga mensalidade?</label>
              <span class="d-block">
                <ui-switch></ui-switch>
                <input disabled class="form-control form-control-lg top02" placeholder="Categoria paga mensalidade?" />
              </span>
            </div>
            <div class="col-lg-6" id="formValorMensalidade">
              <label class="form-label" for="formValorMensalidade">Valor Mensalidade</label>
              <input type="text" disabled class="form-control form-control-lg"
                placeholder="Valor da mensalidade a ser paga" />
            </div>
          </div>
          <div class="w-100 top1"><button type="button" class="btn btn-warning">Cancelar</button><label
              style="margin-left: 4px; margin-right: 1%;">Retorna para tela de lista de categorias.</label></div>
          <div class="w-100 top1"><button type="button" class="btn btn-primary">Salvar</button><label
              style="margin-left: 4px; margin-right: 1%;">Salva a categoria selecionada.</label></div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
