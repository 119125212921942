import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import AtividadeService from '../atividade-service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Atividade } from '../models/atividade';
import { PlanoConta } from '../../planoconta/models/planoconta';
import { SocioResumo } from '../../associados/models/associados';
import PlanoContaService from '../../planoconta/planoconta-service';
import { AssociadosService } from '../../associados/associados.service';
import { Lancamento } from '../../lancamento/models/lancamento';
import LancamentoService from '../../lancamento/lancamento.service';

@Component({
  selector: 'app-atividade-edicao',
  templateUrl: './atividade-edicao.component.html',
  styleUrls: ['./atividade-edicao.component.css'],
})
export class AtividadeEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  codigoPaginaAtividade: number = 1;
  descricaoPlanoConta: string = '';
  form: FormGroup;
  dataAtividade: Date;
  valorAtividade: string = '';
  lancamento: Lancamento[] = [];
  atividade: Atividade;
  listaPlanoConta: PlanoConta[] = [];
  planoContaOriginal: PlanoConta[] = [];
  lancamentoOriginal: Lancamento[] = [];
  planoContaArray: {}[];
  complemento: string = '';
  situacao: string;
  cargaHoraria: string;
  titulo: string;
  dataInicio: Date;
  dataFim: Date;
  tipoPagamento: string;
  valorSocio: string;
  valorNaoSocio: string;
  valorEstudanteSocio: string;
  valorEstudante: string;
  local: string;
  palavraChave: string;
  observacaoGeral: string;
  listaFornecedorOriginal: SocioResumo[] = [];
  listaAssociadoOriginal: SocioResumo[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private atividadeService: AtividadeService,
    private lancamentoService: LancamentoService,
    private planoContaService: PlanoContaService,
    private associadosService: AssociadosService,
    private utilsservice: UtilsService,
    private changeDetector: ChangeDetectorRef
  ) {

    const { atividade } = window.history.state;
    this.atividade = atividade;
    this.situacao = atividade.Situacao;
    this.cargaHoraria = atividade.CargaHoraria;
    this.titulo = atividade.Titulo;
    this.dataInicio = atividade.DataInicio;
    this.dataFim = atividade.DataFim;
    this.tipoPagamento = atividade.TipoPagamento;
    this.valorSocio = atividade.ValorSocio;
    this.valorNaoSocio = atividade.ValorNaoSocio;
    this.valorEstudanteSocio = atividade.ValorEstudanteSocio;
    this.valorEstudante = atividade.ValorEstudante;
    this.local = atividade.Local;
    this.palavraChave = atividade.PalavraChave;
    this.observacaoGeral = atividade.ObservacaoGeral;

  }

  configPlanoConta = {
    search: true,
    placeholder: 'Selecione o plano de conta', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayFn:(item: any) => { return item.CodigoPlanoConta + ' - ' + item.DescricaoPlanoConta; },
    defaultValue:'CodigoPlanoConta',
    height: '220px',
    limitTo: 0,
    searchOnKey: 'DescricaoSelect',

  };
  aba: string = 'A1';

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.spinner.show();
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { lancamento } = window.history.state;
    this.lancamento = lancamento;
    this.criarForm();
    this.spinner.hide();
    this.changeDetector.detectChanges();

  }

  isActiveAba(abaSelecionada: string): boolean {
    return this.aba == abaSelecionada;
  }

  setAba(abaSelecionada: string) {
    this.aba = abaSelecionada;
    console.log(this.atividade.IdAtividade)
    if (abaSelecionada === 'A3') {
      this.changeDetector.detectChanges();
    }
  }

  SalvarAtividade(){

  }

  AlteraDataInicio($event: any, data: string) {
      this.atividade.DataInicio = new Date($event);
  }

  AlteraDataFim($event: any, data: string){
    this.atividade.DataFim = new Date($event);
  }

  criarForm() {
    const plano = new PlanoConta
    this.planoContaArray = this.planoContaOriginal
    this.form = this.formBuilder.group({
      formPlanoContas: [null],
      formSituacao: [this.situacao ? this.situacao : 'A'], // Valor inicial como "Aberto"
      formCargaHoraria: [this.cargaHoraria, [Validators.required]],
      formTitulo: [this.titulo, [Validators.required]],
      formDataInicio: [this.dataInicio],
      formDataFim: [this.dataFim],
      formTipoPagamento: [this.tipoPagamento ? this.atividade.TipoPagamento : 'P'], // Valor inicial como "Pago"
      formValorSocio: [this.valorSocio],
      formValorNaoSocio: [this.valorNaoSocio],
      formValorEstudanteSocio: [this.valorEstudanteSocio],
      formValorEstudante: [this.valorEstudante],
      formLocal: [this.local, [Validators.required]],
      formPalavraChave: [this.palavraChave],
      formObservacaoGeral: [this.observacaoGeral]
    });
}

voltar() {
    this.router.navigateByUrl('/atividades');
  }
}
