<section class="vh-100" style="background-image: url('assets/img/SigFundo.jpg'); background-repeat: no-repeat; background-size: cover;">
  <div class="container py-5 h-100">
    <div class="row d-flex align-items-center justify-content-center h-100" >
      <div class="col-md-6">
        <form [formGroup]="form" style="border-radius: 10px;background-color: #fff;padding: 1rem 3rem 2rem 3rem;">
          <div class="row d-flex align-items-center justify-content-center">
            <img src="assets/img/logosig.png" class="w-50" >
          </div>
          <div class="form-outline mb-4">
            <label class="form-label" for="formUsu">Usuário</label>
            <input type="text" id="formUsu" formControlName="formUsu" name="formUsu"
              class="form-control form-control-lg" [class.is-invalid]="FormUtils.isInvalidField(form.get('formUsu'))"/>
              <app-field-error-message [control]="form.get('formUsu')" label="usuário"></app-field-error-message>
          </div>
          <div class="form-outline mb-4">
            <label class="form-label" for="formSenha">Senha</label>
            <input type="password" id="formSenha" formControlName="formSenha" name="formSenha"
              class="form-control form-control-lg" [class.is-invalid]="FormUtils.isInvalidField(form.get('formSenha'))" />
              <app-field-error-message [control]="form.get('formSenha')" label="senha"></app-field-error-message>
          </div>
          <div class="h-100">
            <span><button type="submit" class="btn btn-primary btn-lg btn-block" (click)="logOn();">Entrar</button></span>
            <span class="float-end" style="margin-top: 25px;"><h6>Versão {{ versao }}</h6></span>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
