<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="table-responsive h-100">

      <div class="card-title-form">Orçamentos
        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar" placement="top"
          [adaptivePosition]="false">
          <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
            <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
        </span>
      </div>

      <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
        <span class="col-lg-8" style="align-content: end;">
          <input type="text" #search id="search" class="form-control form-control-lg "
            placeholder="Pesquisar Plano de Contas, Complemento, Fornecedor ou Data da Compra na listagem abaixo..."
            (keyup)="updateFilter($event)" />
        </span>
        <span class="col-lg-2">
          <label class="form-label">Encerrados? </label>
          <span class="d-block"><ui-switch name="mostrarEncerrados" id="mostrarEncerrados"
              formControlName="mostrarEncerrados" (click)="toggleMostrarEncerrados();"></ui-switch></span>
        </span>
        <span class="col-lg-2" style="align-content: end;">
          <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass"
            tooltip="Exporta para o Excel os dados consultados abaixo." placement="top" [adaptivePosition]="false"
            (click)="generateExcell();">
            <i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar Excel</button>
        </span>
      </div>

      <div class="card-body-form">
        <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="orcamento"
          [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="!isMobile ? 65 : 250"
          [scrollbarH]="false" [scrollbarV]="true" [limit]="7" [messages]="my_messages" style="height: 400px;"
          [sorts]="[{prop: 'DataCompra', dir: 'desc'}]">

          <ngx-datatable-column prop="DescricaoPlanoConta" [draggable]="false" [flexGrow]="5">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Plano de Contas</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.DescricaoPlanoConta}}" placement="auto"
                [adaptivePosition]="false">{{row.DescricaoPlanoConta}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="DescExtraPlanoconta" [draggable]="false" [flexGrow]="3">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Complemento</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.DescExtraPlanoconta}}" placement="auto"
                [adaptivePosition]="false">{{row.DescExtraPlanoconta}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="DataCompra" [draggable]="false" [flexGrow]="2">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Data</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.DataCompra | date: 'dd/MM/yyyy'}}" placement="auto"
                [adaptivePosition]="false">{{ row.DataCompra | date: 'dd/MM/yyyy' }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="NomeFornOrcam1" [draggable]="false" [flexGrow]="3">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Fornecedor 1</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.NomeFornOrcam1}}" placement="auto"
                [adaptivePosition]="false">{{row.NomeFornOrcam1}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="OrcamentoValor1" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Valor 1</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.OrcamentoValor1  | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.OrcamentoValor1 | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="NomeFornOrcam2" [draggable]="false" [flexGrow]="3">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Fornecedor 2</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.NomeFornOrcam2}}" placement="auto"
                [adaptivePosition]="false">{{row.NomeFornOrcam2}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="OrcamentoValor2" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Valor 2</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.OrcamentoValor2  | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.OrcamentoValor2 | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="NomeFornOrcam3" [draggable]="false" [flexGrow]="3">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Fornecedor 3</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.NomeFornOrcam3}}" placement="auto"
                [adaptivePosition]="false">{{row.NomeFornOrcam3}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="OrcamentoValor3" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Valor 3</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.OrcamentoValor3  | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.OrcamentoValor3 | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="2" [sortable]="false"
            [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span class="ngx-datatable-cabecalho">Ações</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top right"
                [adaptivePosition]="false" (click)="Edicao(row);">
                <i class="fa-solid fa-pen-to-square"></i>
              </span>
              <span class="iconeTamanho" containerClass="customClass" tooltip="Impressão" placement="top right"
                [adaptivePosition]="false" (click)="Imprimir(row);">
                <i class="fa-solid fa-print"></i> <!-- Usei um ícone de impressão mais adequado -->
              </span>
              <span class="iconeTamanho" containerClass="customClass" tooltip="Exclusão" placement="top"
                style="padding-left: 6% !important;padding-right: 6% !important;" [adaptivePosition]="false"
                (click)="openModalExcluir(confirmaExclusao, row.CodigoCompra, row.DescricaoPlanoConta, row.DescExtraPlanoconta );">
                <i class="fa-solid fa-trash"></i></span>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>

      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>

<ng-template #confirmaExclusao>
  <div>
    <div class="modal-header modal-header-exclusao">
      <h5 class="modal-title">Confirmação de Exclusão</h5>
    </div>
    <div class="modal-body">
      <p>Confirma a exclusão do orçamento de '{{descricaoOrcamento}}'?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ExcluiOrcamentoByCodigo()">Sim</button>
      <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeModal()">Não</button>
    </div>
  </div>
</ng-template>
