import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { DataSaldoCalc, Saldo } from '../models/saldo';
import saldoService from '../saldo-service';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

@Component({
  selector: 'app-saldo-edicao',
  templateUrl: './saldo-edicao.component.html',
  styleUrls: ['./saldo-edicao.component.css'],
})

export class SaldoEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  form: FormGroup;
  saldo: Saldo;
  dataSaldoCalc: DataSaldoCalc;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private utilsservice: UtilsService,
    private saldoService: saldoService
  ) {}

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { saldo } = window.history.state;
    this.saldo = saldo;
    this.GetDSaldoCalculadoProxData(true);
  }

  novo(): void {
    this.saldo = new Saldo();
    this.saldo.CodigoSaldo = -1;
    this.saldo.DataSaldo = Utils.getNullDate();
    this.dataSaldoCalc = new DataSaldoCalc();
    this.GetDSaldoCalculadoProxData(false);
  }

  GetDSaldoCalculadoProxData(montaForm: boolean): void {
    if (!this.saldo.DataSaldo)
      this.messageService.showAlertDanger('Data do saldo ser informada.');
    else {
      this.spinner.show();
      try {
        this.saldoService
          .GetDSaldoCalculadoProxData(Utils.getDateUTC(this.saldo.DataSaldo))
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.dataSaldoCalc = response.DataSaldoCalc;
                if(this.saldo.CodigoSaldo == -1 )
                {
                  this.saldo.DataSaldo = this.dataSaldoCalc.DataSaldo;
                  this.saldo.ValorContaCorrente = this.dataSaldoCalc.ValorSaldo;
                  this.saldo.ValorFundoFixo = this.dataSaldoCalc.ValorSaldoFF;
                  this.saldo.ValorInicialAplicacao = this.dataSaldoCalc.ValorSaldoAplicacao;
                  this.saldo.ValorAplicacao = this.dataSaldoCalc.ValorSaldoAplicacao;
                  this.saldo.ValorInicialCDB = this.dataSaldoCalc.ValorSaldoCDB;
                  this.saldo.ValorCDB = this.dataSaldoCalc.ValorSaldoCDB;
                }
                if(montaForm)
                { this.criarForm(); }
                else
                { this.SetaForm(); }
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  criarForm() {
    this.form = this.formBuilder.group({
      formDataSaldo: [Utils.getDateUTC(this.saldo.DataSaldo),Validators.required],
      formValorContaCorrente: [this.saldo.ValorContaCorrente],
      formValorFundoFixo: [this.saldo.ValorFundoFixo],
      formValorInicialAplicacao: [this.saldo.ValorInicialAplicacao],
      formDepositoPoupanca: [this.saldo.DepositoPoupanca],
      formRetiradaPoupanca: [this.saldo.RetiradaPoupanca],
      formValorAplicacao: [this.saldo.ValorAplicacao],
      formValorInicialCDB: [this.saldo.ValorInicialCDB],
      formDepositoCDB: [this.saldo.DepositoCDB],
      formRetiradaCDB: [this.saldo.RetiradaCDB],
      formValorCDB: [this.saldo.ValorCDB],
      formDataAnterior: [Utils.getDateUTC(this.dataSaldoCalc.DataSaldoAnterior)],
      formSaldoAnterior: [this.dataSaldoCalc.ValorSaldoAnterior],
      formDataAtual: [Utils.getDateUTC(this.dataSaldoCalc.DataSaldo)],
      formValorCalculo: [this.dataSaldoCalc.ValorSaldo],
    });
  }

  SetaForm() {
    this.form.controls['formDataSaldo'].setValue(Utils.getDateUTC(this.saldo.DataSaldo));
    this.form.controls['formValorContaCorrente'].setValue(this.saldo.ValorContaCorrente);
    this.form.controls['formValorFundoFixo'].setValue(this.saldo.ValorFundoFixo);
    this.form.controls['formValorInicialAplicacao'].setValue(this.saldo.ValorInicialAplicacao);
    this.form.controls['formDepositoPoupanca'].setValue(this.saldo.DepositoPoupanca);
    this.form.controls['formRetiradaPoupanca'].setValue(this.saldo.RetiradaPoupanca);
    this.form.controls['formValorAplicacao'].setValue(this.saldo.ValorAplicacao);
    this.form.controls['formValorInicialCDB'].setValue(this.saldo.ValorInicialCDB);
    this.form.controls['formDepositoCDB'].setValue(this.saldo.DepositoCDB);
    this.form.controls['formRetiradaCDB'].setValue(this.saldo.RetiradaCDB);
    this.form.controls['formValorCDB'].setValue(this.saldo.ValorCDB);
    this.form.controls['formDataAnterior'].setValue(Utils.getDateUTC(this.dataSaldoCalc.DataSaldoAnterior));
    this.form.controls['formSaldoAnterior'].setValue(this.dataSaldoCalc.ValorSaldoAnterior);
    this.form.controls['formDataAtual'].setValue(Utils.getDateUTC(this.dataSaldoCalc.DataSaldo));
    this.form.controls['formValorCalculo'].setValue(this.dataSaldoCalc.ValorSaldo);
  }

  AlteraData($event: any) {
    this.saldo.DataSaldo = new Date($event);
  }

  SalvaSaldo(executaNovo: boolean): void {
    this.saldo.ValorContaCorrente = this.form.get(
      'formValorContaCorrente'
    )?.value;
    this.saldo.ValorFundoFixo = this.form.get('formValorFundoFixo')?.value;
    this.saldo.ValorInicialAplicacao = this.form.get(
      'formValorInicialAplicacao'
    )?.value;
    this.saldo.DepositoPoupanca = this.form.get('formDepositoPoupanca')?.value;
    this.saldo.RetiradaPoupanca = this.form.get('formRetiradaPoupanca')?.value;
    this.saldo.ValorAplicacao = this.form.get('formValorAplicacao')?.value;
    this.saldo.ValorInicialCDB = this.form.get('formValorInicialCDB')?.value;
    this.saldo.DepositoCDB = this.form.get('formDepositoCDB')?.value;
    this.saldo.RetiradaCDB = this.form.get('formRetiradaCDB')?.value;
    this.saldo.ValorCDB = this.form.get('formValorCDB')?.value;

    if (!this.saldo.DataSaldo)
      this.messageService.showAlertDanger('Data do saldo ser informada.');
    else {
      this.spinner.show();
      try {
        this.saldoService
          .SalvaSaldo(this.saldo)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              if(this.saldo.CodigoSaldo == -1 && executaNovo)
              { this.novo(); }
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.messageService.showAlertSuccess("Saldo salvo com sucesso");
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  voltar() {
    this.router.navigateByUrl('/administrativo/saldos');
  }

}
