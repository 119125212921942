import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Projeto/guard/auth.guard';
import { LoginComponent } from './Projeto/Autenticacao/Login/Login.component';
import { Error404Component } from './Projeto/Auxiliares/error-404/error-404.component';
import { HomeComponent } from './Projeto/Auxiliares/Home/home.component';
import { SalasListaComponent } from './Sistema/salas/salas-lista/salas-lista.component';
import { SalasOcupacaoComponent } from './Sistema/salas/salas-ocupacao/salas-ocupacao.component';
import { SalasEdicaoComponent } from './Sistema/salas/salas-edicao/salas-edicao.component';
import { AssociadosListaComponent } from './Sistema/associados/associados-lista/associados-lista.component';
import { AssociadosEdicaoComponent } from './Sistema/associados/associados-edicao/associados-edicao.component';
import { AssociadosEdicaoCategComponent } from './Sistema/associados/associados-edicao-categ/associados-edicao-categ.component';
import { AssociadosEdicaoDispComponent } from './Sistema/associados/associados-edicao-disponibilidade/associados-edicao-disponibilidade.component';
import { AssociadosListaMensalidadeComponent } from './Sistema/associados/associados-lista-mensalidade/associados-lista-mensalidade.component';
import { AssociadosEdicaoMensalidadeComponent } from './Sistema/associados/associados-edicao-mensalidade/associados-edicao-mensalidade.component';
import { AreaAtuacaoListaComponent } from './Sistema/areaatuacao/areaatuacao-lista/areaatuacao-lista.component';
import { AreaAtuacaoEdicaoComponent } from './Sistema/areaatuacao/areaatuacao-edicao/areaatuacao-edicao.component';
import { CategoriaListaComponent } from './Sistema/categoria/categoria-lista/categoria-lista.component';
import { CategoriaEdicaoComponent } from './Sistema/categoria/categoria-edicao/categoria-edicao.component';
import { PlanoContaListaComponent } from './Sistema/planoconta/planoconta-lista/planoconta-lista.component';
import { PlanoContaEdicaoComponent } from './Sistema/planoconta/planoconta-edicao/planoconta-edicao.component';
import { PrevisaoListaComponent } from './Sistema/previsao/previsao-lista/previsao-lista.component';
import { PrevisaoEdicaoComponent } from './Sistema/previsao/previsao-edicao/previsao-edicao.component';
import { OrcamentoListaComponent } from './Sistema/orcamento/orcamento-lista/orcamento-lista.component';
import { OrcamentoEdicaoComponent } from './Sistema/orcamento/orcamento-edicao/orcamento-edicao.component';
import { LancamentoListaComponent } from './Sistema/lancamento/lancamento-lista/lancamento-lista.component';
import { LancamentoEdicaoComponent } from './Sistema/lancamento/lancamento-edicao/lancamento-edicao.component';
import { AtividadeListaComponent } from './Sistema/atividades/atividade-lista/atividade-lista.component';
import { AtividadeEdicaoComponent } from './Sistema/atividades/atividade-edicao/atividade-edicao.component';
import { ProfissionalListaComponent } from './Sistema/atividades/profissional/profissional-lista/profissional-lista.component';
import { InscricaoListaComponent } from './Sistema/atividades/inscricao/inscricao-lista/inscricao-lista.component';
import { RelatoriosComponent } from './Sistema/Relatorios/relatorios.component';
import { RotinasComponent } from './Sistema/rotinas/rotinas.component';
import { DashboardComponent } from './Sistema/dashboard/dashboard.component';
import { SaldoListaComponent } from './Sistema/saldo/saldo-lista/saldo-lista.component';
import { SaldoEdicaoComponent } from './Sistema/saldo/saldo-edicao/saldo-edicao.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'salas/cadastro', component: SalasListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'salas/ocupacao', component: SalasOcupacaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'salas/edicao', component: SalasEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'cadastrogeral/lista', component: AssociadosListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'cadastrogeral/edicao', component: AssociadosEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
	{ path: 'cadastrogeral/edicaocateg', component: AssociadosEdicaoCategComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'cadastrogeral/edicaodisp', component: AssociadosEdicaoDispComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'cadastrogeral/listamens', component: AssociadosListaMensalidadeComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'cadastrogeral/edicaomens', component: AssociadosEdicaoMensalidadeComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/areaatuacao', component: AreaAtuacaoListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/areaatuacao/edicao', component: AreaAtuacaoEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/categoria', component: CategoriaListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/categoria/edicao', component: CategoriaEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/planocontas', component: PlanoContaListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/planocontas/edicao', component: PlanoContaEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/previsoes', component: PrevisaoListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/previsoes/edicao', component: PrevisaoEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/orcamentos', component: OrcamentoListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/orcamento/edicao', component: OrcamentoEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/lancamentos', component: LancamentoListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/lancamentos/edicao', component: LancamentoEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/saldos', component: SaldoListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/saldos/edicao', component: SaldoEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'atividades', component: AtividadeListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'atividades/edicao', component: AtividadeEdicaoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'atividades/profissional', component: ProfissionalListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'atividades/inscricao', component: InscricaoListaComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'relatorios', component: RelatoriosComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/rotinas', component: RotinasComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: 'administrativo/dashboard', component: DashboardComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: Error404Component, pathMatch: 'full', canActivate: [AuthGuard], canLoad: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
