<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="table-responsive h-100">

      <div class="card-title-form">Saldo do Dia
        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar" placement="top"
          [adaptivePosition]="false">
          <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();">
            <i class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
        </span>
      </div>

      <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
        <span class="col-lg-8">
          <input type="text" #search id="search" class="form-control form-control-lg "
            placeholder="Pesquisar data na listagem abaixo..." (keyup)="updateFilter($event)" />
        </span>

        <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-4">
          <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass"
            tooltip="Exporta para o Excel os dados consultados abaixo." placement="top" [adaptivePosition]="false"
            (click)="generateExcell();"><i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar
            Excel</button>
        </span>
      </div>

      <div class="card-body-form">
        <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="saldo"
          [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50"
          [rowHeight]="!isMobile ? 40 : 250" [scrollbarH]="false" style="height: 360px;" [scrollbarV]="true" [limit]="7"
          [messages]="my_messages" [sorts]="[{prop: 'DataSaldo', dir: 'desc'}]">

          <ngx-datatable-column prop="DataSaldo" [draggable]="false" [flexGrow]="2">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Data Saldo</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Data Saldo</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.DataSaldo  | date: 'dd/MM/yyyy'}}" placement="auto"
                [adaptivePosition]="false">{{ row.DataSaldo | date: 'dd/MM/yyyy' }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="ValorContaCorrente" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Conta Corrente</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Conta Corrente</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.ValorContaCorrente | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.ValorContaCorrente | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="ValorFundoFixo" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Fundo Fixo</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Fundo Fixo</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.ValorFundoFixo | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.ValorFundoFixo | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="ValorAplicacao" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Poupança</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Poupança</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.ValorAplicacao | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.ValorAplicacao | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="DepositoPoupanca" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Depósito</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Depósito</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.DepositoPoupanca | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.DepositoPoupanca | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="RetiradaPoupanca" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Retirada</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Retirada</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.RetiradaPoupanca | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.RetiradaPoupanca | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="ValorCDB" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">CDB</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">CDB</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.ValorCDB | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.ValorCDB | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="DepositoCDB" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Depósito CDB</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Depósito CDB</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.DepositoCDB | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.DepositoCDB | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column prop="RetiradaCDB" [draggable]="false" [flexGrow]="2" [cellClass]="'ngx-direita'"
            [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Retirada CDB</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Retirada CDB</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span containerClass="customClass" tooltip="{{row.RetiradaCDB | currency: 'BRL'}}" placement="auto"
                [adaptivePosition]="false">{{row.RetiradaCDB | currency: 'BRL'}}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="1" [sortable]="false"
            [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
            <ng-template ngx-datatable-header-template>
              <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Ações</span>
              <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Ações</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top right"
                [adaptivePosition]="false" (click)="Edicao(row);">
                <i class="fa-solid fa-pen-to-square"></i></span>
              <span class="iconeTamanho" containerClass="customClass" tooltip="Exclusão" placement="top"
                style="padding-left: 6% !important;padding-right: 6% !important;" [adaptivePosition]="false"
                (click)="openModalExcluir(confirmaExclusao, row.CodigoSaldo, row.NomePlanoConta);">
                <i class="fa-solid fa-trash"></i></span>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>
      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>

<ng-template #confirmaExclusao>
  <div>
    <div class="modal-header modal-header-exclusao">
      <h5 class="modal-title">Confirmação de Exclusão</h5>
    </div>
    <div class="modal-body">
      <p>Confirma a exclusão do saldo do dia '{{dataSaldo}}'?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ExcluiSaldoByCodigo()">Sim</button>
      <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeModal()">Não</button>
    </div>
  </div>
</ng-template>
