import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';

import LancamentoService from '../lancamento.service';
import { Lancamento } from '../models/lancamento';
import { PlanoConta } from '../../planoconta/models/planoconta';
import { SocioResumo } from '../../associados/models/associados';
import { TipoLancamento } from '../models/tipoLancamento';

@Component({
  selector: 'app-lancamento-edicao',
  templateUrl: './lancamento-edicao.component.html',
  styleUrls: ['./lancamento-edicao.component.css'],
})

export class LancamentoEdicaoComponent implements OnInit {

  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  form: FormGroup;
  lancamento: Lancamento;
  recebeuBoleto: boolean = false;
  compensado: boolean = false;
  estoqueAntigo: boolean = false;
  codigoPlanoConta: number = 0;
  codigoFornecedor: number = 0;
  codigoSocioLancamento: number = 0;
  codigoTipoLancamento: number = 0;
  listaTipoLancamentoOriginal: TipoLancamento[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private utilsservice: UtilsService,
    private lancamentoService: LancamentoService,
  ) {}

  configTipoLan = {
    search: true,
    placeholder: 'Selecione o tipo lançamento,', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'NomeTipoLancamento', //TROCA CAMPO DESCRIÇÃO
    height: '220px',
    limitTo: 0,
  };

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.spinner.show();
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaTipoLancamento();
    const { lancamento } = window.history.state;
    this.lancamento = lancamento;
    this.recebeuBoleto = lancamento.RecebeuBoleto === 'S' ? true : false;
    this.compensado = lancamento.Compensado === 'S' ? true : false;
    this.estoqueAntigo = lancamento.Estoque;
    this.codigoPlanoConta = lancamento.CodigoPlanoConta;
    this.codigoFornecedor = lancamento.CodigoFornecedor;
    this.codigoSocioLancamento = lancamento.CodigoSocioLancamento;
    this.codigoTipoLancamento = lancamento.CodigoTipoLancamento;
    this.lancamento.DataLancamento = Utils.getDateUTC(lancamento.DataLancamento);
    this.criarForm();
    this.spinner.hide();
  }

  BuscaTipoLancamento(): void {
    try {
      this.lancamentoService
        .GetTipoLancamento()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.listaTipoLancamentoOriginal = response.TipoLancamentoLista;
            }
          },
          error: (error) => {
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.utilsservice.showHttpError(error);
    }
  }

  SalvaLancamento(): void {
    let requestLancamento: Lancamento = new Lancamento();

    requestLancamento = this.lancamento;
    requestLancamento.CodigoFornecedor = this.codigoFornecedor;
    requestLancamento.CodigoPlanoConta = this.codigoPlanoConta;
    requestLancamento.CodigoTipoLancamento = this.codigoTipoLancamento;
    requestLancamento.CodigoSocioLancamento = this.codigoSocioLancamento;
    requestLancamento.DescExtraPlanoconta =  this.form.get('formComplemento')?.value;
    requestLancamento.DataLancamento =  Utils.getDateUTC(this.lancamento.DataLancamento);
    requestLancamento.ValorLancamento = this.form.get('formValorLancamento')?.value;
    requestLancamento.NroCheque = this.form.get('formNumeroLancamento')?.value;
    requestLancamento.Compensado = this.compensado === true ? 'S' : 'N';
    requestLancamento.RecebeuBoleto = this.recebeuBoleto === true ? 'S' : 'N';
    requestLancamento.Estoque = this.estoqueAntigo;

    if (!requestLancamento.CodigoPlanoConta)
      this.messageService.showAlertDanger(
        'Plano de Contas deve ser informado.'
      );
    else if (
      !requestLancamento.CodigoFornecedor &&
      !requestLancamento.CodigoSocioLancamento
    )
      this.messageService.showAlertDanger(
        'Fornecedor ou Associado precisa ser informado.'
      );
    else if (!requestLancamento.DataLancamento)
      this.messageService.showAlertDanger('Data precisa ser informado.');
    else if (!requestLancamento.ValorLancamento)
      this.messageService.showAlertDanger('Valor precisa ser informado.');
    else if (!requestLancamento.CodigoTipoLancamento)
      this.messageService.showAlertDanger(
        'Tipo Lançamento precisa ser informado.'
      );
    else {
      this.spinner.show();
      try {
        this.lancamentoService
          .SalvaLancamento(requestLancamento)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  AlteraData($event: any) {
    this.lancamento.DataLancamento = new Date($event);
  }

  toggleRecebeuBoleto() {
    this.recebeuBoleto = !this.recebeuBoleto;
  }

  toggleCompensado() {
    this.compensado = !this.compensado;
  }

  toggleEstoqueAntigo() {
    this.estoqueAntigo = !this.estoqueAntigo;
  }

  changePlanoConta($event: PlanoConta) {
    this.codigoPlanoConta = $event.CodigoPlanoConta;
    this.lancamento.DescricaoPlanoConta = $event.DescricaoPlanoConta;
  }

  changeFornec($event: SocioResumo) {
    this.codigoFornecedor = $event.CodigoSocio;
    this.lancamento.NomeFornecedor = $event.NomeSocio;
  }

  changeAssociado($event: SocioResumo) {
    this.codigoSocioLancamento = $event.CodigoSocio;
    this.lancamento.NomeSocioLancamento = $event.NomeSocio;
  }

  changeTipoLanc($event: TipoLancamento) {
    this.codigoTipoLancamento = $event.CodigoTipoLancamento;
    this.lancamento.NomeTipoLancamento = $event.NomeTipoLancamento;
  }

  changeRepeticao() {
    //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
    this.lancamento.Repeticao = this.form.get("formNumeroParcela")?.value;
  }

  criarForm() {

    this.form = this.formBuilder.group({
      formDataLancamento: [this.lancamento.DataLancamento, Validators.required],
      formValorLancamento: [this.lancamento.ValorLancamento , Validators.required],
      formContaPlano: [this.codigoPlanoConta],
      formFornecedor: [this.codigoFornecedor],
      formTipoLancamento: [
        {
          value: this.codigoTipoLancamento,
          disabled: this.lancamento.CodigoLancamento > 0,
        },
      ],
      formAssociado: [this.codigoSocioLancamento],
      formComplemento: [this.lancamento.DescExtraPlanoconta],
      formNumeroLancamento: [this.lancamento.NroCheque],
      formNumeroParcela: [
        { value: 1, disabled: this.lancamento.CodigoLancamento > 0 },
      ],
      formRecebeuBoleto: [this.recebeuBoleto],
      formCompensado: [this.compensado],
      formEstoqueAntigo: [this.estoqueAntigo],
    });

  }

  voltar() {
    this.router.navigateByUrl('/administrativo/lancamentos');
  }

}
