import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Categoria } from '../../categoria/models/categoria';
import { CategoriaService } from '../../categoria/categoria.service';
import { CategoriaHistoricoService } from '../../categoriahistorico/categoriahistorico.service';
import { CategoriaHistorico } from './../../categoriahistorico/models/categoriahistorico';
import { Associado } from '../models/associados';

@Component({
  selector: 'app-associados-edicao-categ',
  templateUrl: './associados-edicao-categ.component.html',
  styleUrls: ['./associados-edicao-categ.component.css'],
})
export class AssociadosEdicaoCategComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  assoc: Associado  = new Associado();
  associadosHistorico: CategoriaHistorico = new CategoriaHistorico();
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private categoriaService: CategoriaService,
    private categoriaHistService: CategoriaHistoricoService,
    private utilsservice: UtilsService
  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { CodSocioCategoriaHist , associado } = window.history.state;
    this.assoc = associado;
    this.associadosHistorico.CodigoSocioCategoriaHist = CodSocioCategoriaHist;
    this.associadosHistorico.CodigoSocio = this.assoc.CodigoSocio;
    this.criarForm();
    if(this.associadosHistorico.CodigoSocioCategoriaHist != -1)
    { this.BuscaCategoriaHistorico(); }

  }

  criarForm() {
    this.form = this.formBuilder.group({
      formCategoria: ['', [Validators.required, Validators.maxLength(50)]],
      DataCriacao: [''],
      DataEncerramento: [''],
      Observacoes: ['', [Validators.maxLength(250)]]
    });
  }

  BuscaCategoriaHistorico() {
    this.spinner.show();
    try {
      this.categoriaHistService
        .BuscaCategoriaHistorico(this.associadosHistorico.CodigoSocioCategoriaHist)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.associadosHistorico = response.CategoriaHistorico;
              if (this.associadosHistorico.DataCriacao != null)
              { this.associadosHistorico.DataCriacao = Utils.getDateUTC(this.associadosHistorico.DataCriacao); }
              else
              { this.associadosHistorico.DataCriacao = new Date(); }
              if (this.associadosHistorico.DataEncerramento != null)
              { this.associadosHistorico.DataEncerramento = Utils.getDateUTC(this.associadosHistorico.DataEncerramento); }
              this.form.controls['DataCriacao'].setValue(this.associadosHistorico.DataCriacao);
              this.form.controls['DataEncerramento'].setValue(this.associadosHistorico.DataEncerramento);
              this.form.controls['Observacoes'].setValue(this.associadosHistorico.Observacoes);
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }

  }

  voltar() {
    this.router.navigateByUrl('/cadastrogeral/edicao', {
      state: { associado: this.assoc, Origem: 'CATEG' },
    });
  }

  Salvar() {

    if (this.associadosHistorico.CodigoCategoria < 1) {this.messageService.showAlertDanger("Categoria deve ser informada."); }
    else if (this.associadosHistorico.DataCriacao.toString().trim() == "Invalid Date" || this.associadosHistorico.DataCriacao.toString().trim() == "Data Inválida") { this.messageService.showAlertDanger("Data inicial inválida."); }
    else {
      this.spinner.show();
      try {
        this.categoriaHistService
          .SalvaCategoriaHistorico(this.associadosHistorico)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }


  changeCategoria($eventCategoria: Categoria) {
    this.associadosHistorico.CodigoCategoria = $eventCategoria.CodigoCategoria;
    this.associadosHistorico.DescricaoCategoria = $eventCategoria.DescricaoCategoria;
  }

  AlteraData($event: any,data: string)
  {
    if (data == 'DataCriacao')
    {
      //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
      this.associadosHistorico.DataCriacao = new Date($event);
    }
    else if (data == 'DataEncerramento')
    {
      //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
      this.associadosHistorico.DataEncerramento = new Date($event);
    }
  }

  changeObs() {
    this.associadosHistorico.Observacoes = this.form.get('Observacoes')?.value;
  }

}
