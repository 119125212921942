import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetDataSaldoCalc, RetSaldoLista } from './models/ret-saldo';
import { Saldo } from './models/saldo';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';

@Injectable({
  providedIn: 'root',
})
export default class SaldoService {
  private readonly baseURL: string = '';
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private authStorageService: AuthStorageService
  ) {
    this.baseURL = `${environment.SaldoWS}`;
  }

  GetListaGeral(): Observable<RetSaldoLista> {
    const url = `${this.baseURL}/ListaGeral`;
    return this.httpClient.get<RetSaldoLista>(url).pipe(
      take(1),
      map((response: RetSaldoLista) => {
        return response;
      })
    );
  }

  SalvaSaldo(Saldo: Saldo): Observable<RetError> {
    let bodyString = JSON.stringify(Saldo);
    const url = `${this.baseURL}/SalvaSaldo`;
    return this.httpClient.post<RetError>(url, bodyString).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );
  }

  ExcluiSaldoByCodigo(CodigoSaldo: number): Observable<RetError> {
    const params = new HttpParams().set('CodigoSaldo', CodigoSaldo);
    const url = `${this.baseURL}/ExcluiSaldoByCodigo`;
    return this.httpClient.get<RetError>(url, { params: params }).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );
  }

  GetDSaldoCalculadoProxData(DataSaldo: Date): Observable<RetDataSaldoCalc> {
    const params = new HttpParams().set('DataSaldo', DataSaldo.toDateString());
    const url = `${this.baseURL}/GetDSaldoCalculadoProxData`;
    return this.httpClient.get<RetDataSaldoCalc>(url, { params: params }).pipe(
      take(1),
      map((response: RetDataSaldoCalc) => {
        return response;
      })
    );
  }

}
