import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetLancamentoLista } from './models/ret-lancamento';
import { RetTipoLancamentoLista } from './models/ret-tipoLancamento';
import { FiltroLancamento, Lancamento } from './models/lancamento';
import { RetError } from 'src/app/Projeto/models/ret-error';

@Injectable({
  providedIn: 'root',
})

export default class LancamentoService {
  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient) {
    this.baseURL = `${environment.LancamentoWS}`;
  }

  GetListaGeral(Filtro: FiltroLancamento): Observable<RetLancamentoLista> {

    let bodyString = JSON.stringify(Filtro);

    const url = `${this.baseURL}/ListaGeral`;
    return this.httpClient.post<RetLancamentoLista>(url,bodyString).pipe(
      take(1),
      map((response: RetLancamentoLista) => {
        return response;
      })
    );
  }

  GetTipoLancamento(): Observable<RetTipoLancamentoLista> {

    const url = `${this.baseURL}/TipoLancamento`;
    return this.httpClient.get<RetTipoLancamentoLista>(url).pipe(
      take(1),
      map((response: RetTipoLancamentoLista) => {
        return response;
      })
    );
  }

  SalvaLancamento(lancamento: Lancamento): Observable<RetError> {
    let bodyString = JSON.stringify(lancamento);

    const url = `${this.baseURL}/SalvaLancamento`;
    return this.httpClient.post<RetError>(url, bodyString).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );
  }

  ExcluiLancamentoByCodigo(CodigoLancamento: number): Observable<RetError> {
    const params = new HttpParams().set('CodigoLancamento', CodigoLancamento);

    const url = `${this.baseURL}/ExcluiLancamentoByCodigo`;
    return this.httpClient.get<RetError>(url, { params: params }).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );
  }
}
